import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  AfterViewInit,
  ElementRef,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ViewChildren, QueryList } from '@angular/core';

import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
  NgbDatepicker,
} from '@ng-bootstrap/ng-bootstrap';
import { LawyerService } from '../../../shared/services/lawyer.service';
import { Entity } from 'src/app/shared/interfaces/user';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from '../../../shared/helpers/sweetalert';
import { pluck } from 'rxjs/operators';
import { from } from 'rxjs';
import * as moment from 'moment';
import { TitlePageService } from 'src/app/shared/services/title-page.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BranchesLaw } from 'src/app/shared/interfaces/common';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-lawyer-cases',
  templateUrl: './cases.component.html',
  styleUrls: ['./cases.component.css'],
})

export class LawyerCasesComponent implements OnInit, AfterViewInit {

  @ViewChild('entityModal') entityModal: TemplateRef<any>;

  editSelectedFilename = {
    current: null,
    old: null,
  };

  dropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'text',
    itemsShowLimit: 5,
    allowSearchFilter: false,
    enableCheckAll: false
  };

  dropdownList = [];
  selectedItems = [];
  branchLaw: BranchesLaw[] = [];

  selected = false;

  searchFormTimeout = null;
  form: FormGroup;
  isSubmitted = false;
  clients = [];
  cases = [];
  case;
  caseId: any;
  statuses = [];
  entities: Entity[] = [];
  modal: any;
  isEdit = false;
  typeCase = [
    { id: 1, typeString: 'Proceso' },
    { id: 2, typeString: 'Trámite' },
  ];
  formElement;
  docsFormData: FormData;
  docsFormDocuments: FormData;
  docsForm: FormGroup;
  fase = 0;
  docum = 0;
  customPhases;
  submitted = false;
  docum2 = 0;
  documsList: any = [];
  cotList: any = [];
  active = 1;
  idCase;
  comment: string = '';
  idCaseSearch;
  order_id: number = 0;
  @Output() submitEvent = new EventEmitter<any>();
  dataExpense: any = [];
  expenseFormData: FormData;
  pageExpense: Boolean = false;
  isEditExpense: Boolean = false;
  submittedE: Boolean = false;
  active_cases: any;
  formExpense: FormGroup;
  fileExpense: any;
  listComment = [];
  dataPaginationComment;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  actual_phase: number;
  expenseEditId: number;
  extraFields = false;
  dataHistory: any = [];
  dataHistoryPagination: any;
  selectedHistoryChanges: any = [];
  expenseFile: File | null = null;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private lawyerService: LawyerService,
    public formatter: NgbDateParserFormatter,
    titlePageService: TitlePageService,
    private storage: StorageService,
    private calendar: NgbCalendar,
    private common: CommonService
  ) {
    titlePageService.titlePageObservableData = { name: 'Listado de casos' };
  }

  get f(): any {
    return this.docsForm.controls;
  }
  get e(): any {
    return this.formExpense.controls;
  }
  get phases(): FormArray {
    return this.docsForm.get('phases') as FormArray;
  }

  get phases_options(): any[] {
    return this.phases?.controls?.filter((phase) => phase.get('phase').value && phase.get('description').value && phase.get('percent').value) ?? [];
  }
  get documents(): FormArray {
    return this.docsForm.get('documents') as FormArray;
  }
  get contrapartes() {
    return this.docsForm.get('contraparte') as FormArray;
  }
  get apoderados() {
    return this.docsForm.get('apoderados') as FormArray;
  }

  ngAfterViewInit() {

    this.route.queryParams.subscribe(params => {

      if (params['case']) {
        this.showCase(this.entityModal, { id: params['case'] });
      }

    });

  }

  


  ngOnInit(): void {
    this.loadData();
    this.loadDataClients();
    this.loadListCases();
    this.loadStatusesCases();
    this.createForm();
    this.common.brachLawyer().subscribe((res) => {
      this.branchLaw = res.data;
      this.configDropdownList();
    });
  }

  configDropdownList() {
    this.dropdownList = [];
    for (var i = 0; i < this.branchLaw.length; i++) {
      this.dropdownList.push({ id: this.branchLaw[i].id, name: this.branchLaw[i].name });
    }

  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }

  async loadStatusesCases() {
    let response: any = await this.lawyerService.loadStatusesCases();
    this.statuses = response;
  }

  // onSubmit(): void { }

  onChange(typeCase: string, type: string): void {
    if (type == 'typeCase') {
      this.extraFields = typeCase == 'Proceso';
    }
  }
  onChangeEntitie(entitie: number, type: string) { }

  private createForm(): void {
    this.docsForm = this.formBuilder.group({
      type: ['', [Validators.required]],
      lawyer_entity_id: ['', [Validators.required]],
      name: ['', [Validators.required]],
      description: ['', [Validators.required]],
      lawyer_client_id: ['', [Validators.required]],
      court: ['', []],
      jurisdiction: ['', []],
      fileNumber: ['', []],
      contraparte: this.formBuilder.array([this.formBuilder.control('')]),
      apoderados: this.formBuilder.array([this.formBuilder.control('')]),
      branch: ['', []],
      phases: this.formBuilder.array([]),
      documents: this.formBuilder.array([]),
      phases_number: ['', [Validators.required]],
      case_status_id: ['', [Validators.required]],
      assigned_number: ['', [Validators.required]],
      init_date: ['', [Validators.required]],
      office: ['', [Validators.required]],
      representative: ['', [Validators.required]],
      price: ['', [Validators.required]],
      judge: ['', []],
      fiscal: ['', []],
      external_quote_file: ['', []],
    });
  }

  deleteFile(item) {
    const index = this.documsList.indexOf(item);
    if (index > -1) {
      this.documsList.splice(index, 1);
    }
  }

  private loadData(): void {
    this.lawyerService.entitites().subscribe((response) => {
      this.entities = [...response.data];
    });
  }

  private loadDataClients(): void {
    this.lawyerService.lawyerClients().subscribe((response) => {
      this.clients = [...response.data];
    });
  }

  private loadListCases(): void {
    console.log('Loading cases with filters', this.filters);

    this.lawyerService.lawyergetCases(1, this.filters).subscribe((response) => {
      this.cases = [...response.data];
      let resp = response as any;
      this.active_cases = resp.info_object.Activos;
      //console.log(this.cases);
    });
  }

  addContraparte(c: string = '') {
    const exists = this.contrapartes.controls.some((control) => control.value == '');
    if (!exists) this.contrapartes.push(this.formBuilder.control(c));
    else if (c != '') this.contrapartes.push(this.formBuilder.control(c));
  }

  removeContraparte(index: number) {
    if (index >= 0) this.contrapartes.removeAt(index)
  }

  addApoderado(a: string = '') {
    const exists = this.apoderados.controls.some((control) => control.value == '');
    if (!exists) this.apoderados.push(this.formBuilder.control(a));
    else if (a != '') this.apoderados.push(this.formBuilder.control(a));
  }

  removeApoderado(index: number) {
    if (index >= 0) this.apoderados.removeAt(index)
  }

  loadListCasesSearch(id): void {
    if (id !== '') {
      this.lawyerService.lawyergetCasesSearch(id).subscribe((response) => {
        this.cases = [...response.data];
        let resp = response as any;
        this.active_cases = resp.info_object.Activos;
      });
    } else {
      this.loadListCases();
    }
  }

  add(entityModal: any): void {
    this.createForm();
    this.idCase = null
    let user = this.storage.getItem('ln_user') as any;

    if (user.lawyer.plan_id == 3 && this.active_cases == 10) {
      Swal.fire({
        title: '',
        text: 'Ha alcanzado el numero maximo de casos permitidos por su plan (10), actualice de plan para continuar con esta acción.',
        icon: 'warning',
        confirmButtonColor: '#212F54'
      });
      return;
    }

    if (this.entities.length > 0 && this.clients.length > 0) {
      this.docsForm.reset();
      this.isEdit = false;
      this.modal = this.modalService.open(entityModal, { size: 'lg' });
    } else {
      Swal.fire({
        title: '',
        text: 'Para generar un caso, debe crear minimo un Cliente y una Entidad en la opción de Configuración',
        icon: 'warning',
        confirmButtonColor: '#212F54'
      });
    }
  }

  closed() {
    this.submitted = false;
    this.modalService.dismissAll();
    this.case = [];
    this.docsForm.reset();
    this.phases.clear();
    this.documents.clear();
    this.closedExpense();
  }

  edit(modal, data) {
    this.isEdit = true;
    this.case = { ...data };
    // this.customPhases = JSON.parse(this.case.phases);
    // this.createForm();

    console.log(this.case);

    this.docsForm.patchValue(data);
    this.modal = this.modalService.open(modal, { backdrop: 'static' });
  }

  async OnSubmit() {
    //console.log(this.isEdit);
    if (this.isEdit) {
      try {
        await this.LoadDocument();
        await this.editData();
      } catch (error) {
        console.log(error);
      } finally {
        Swal.fire({
          // position: 'top-end',
          icon: 'success',
          title: 'Se ha actualizado el caso con exito.',
          showConfirmButton: true,
          confirmButtonColor: '#212F54'
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.closed();
            this.docum = 0;
            this.loadListCases();
          }
        });
      }
    } else {
      this.sendData();
    }
  }

  editData() {
    // const formD = document.getElementById('form') as HTMLFormElement;
    // console.log(formD);
    // this.docsFormData = new FormData(formD);
    this.CreateFormEditCase();

    if (this.docsFormData.get('external_quote_file') == 'null') {
      this.docsFormData.delete('external_quote_file');
    }
    this.docsFormData.append('actual_phase', `${this.actual_phase}`);
    console.log('setdebug 4', this.docsFormData)
    this.lawyerService.updateCases(this.docsFormData, this.case.id).subscribe(
      async (response) => { },
      (error) => { }
    );
  }

  CreateFormEditCase() {
    let form = this.docsForm.value;
    this.docsFormData = new FormData();
    console.log(form)
    console.log('setdebug 1', form)
    const object = Object.assign(form);
    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        const element = object[key];

        if (key == 'phases') {
          for (let index = 0; index < element.length; index++) {
            const arg = element[index];

            object[key][index]['phase'] = '';
            object[key][index]['phase'] = object[key][index]['description'];

            this.docsFormData.set(
              `phases[${index}][phase]`,
              object[key][index]['phase']
            );
            this.docsFormData.set(
              `phases[${index}][percent]`,
              object[key][index]['percent']
            );
          }
        } else if (key == 'init_date') {
          let init =
            element['year'] + '-' + element['month'] + '-' + element['day'];
          let date = moment(init).format('YYYY-MM-DD');
          // object[key] = date;
          this.docsFormData.set(key, date);
        } else if (key === 'external_quote_file') {
          if (typeof element != 'string') {
            this.docsFormData.set(key, element);
          }
        } else if (['contraparte', 'apoderados'].includes(key)) {
          for (let index = 0; index < element.length; index++) {
            const text = element[index];
            if (!text || text == '') continue
            this.docsFormData.set(
              `${key}[${index}]`,
              text
            );
          }
        } else if (key != 'documents' && element != null) {
          this.docsFormData.set(key, element);
          console.log('setdebug 2', key, element)
        }
      }
    }
    // console.log(this.docsForm.value,object);
    console.log('setdebug 3', object)
    return object;
  }
  /*integracion */

  sendData() {
    this.submitted = true;
    //console.log(this.docsForm);
    this.docsForm.setValue({
      ...this.docsForm.value,
      case_status_id: 1
    })
    if (this.docsForm.valid) {
      const percentage: number[] = [];
      from(this.docsForm.value.phases)
        .pipe(pluck('percent'))
        .subscribe((val: number) => percentage.push(val));

      const formD = document.getElementById('form') as HTMLFormElement;
      this.docsFormData = new FormData(formD);

      if (this.docsForm.value?.contraparte?.length) {
        this.docsForm.value?.contraparte.forEach((contra: string, index: number) => {
          this.docsFormData.set(`contraparte[${index}]`, contra);
        });
      }

      if (this.docsForm.value?.apoderados?.length) {
        this.docsForm.value?.apoderados.forEach((apoderado: string, index: number) => {
          this.docsFormData.set(`apoderados[${index}]`, apoderado);
        });
      }
    console.log(this.docsForm.value);
      this.lawyerService.regCases(this.docsFormData).subscribe(
        async (response) => {
          this.closed();

          setTimeout(() => {
            Swal.fire({
              // position: 'top-end',
              icon: 'success',
              title: response.message,
              showConfirmButton: true,
              confirmButtonColor: '#212F54'
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                this.submitted = false;
                this.loadListCases();
                this.docsForm.controls.phases_number.patchValue(0);
              }
            });
          }, 100);
        },
        (error) => {
          console.log(error);

          const errorKeys = Object.keys(error.errors);
          const firstErrorKey = errorKeys.find(
            (key) => error.errors[key].length > 0
          );
          const firstError = error.errors[firstErrorKey][0];

          Swal.fire('', firstError, 'error');
        }
      );
    }
  }
  //

  async LoadDocument() {
    this.docsFormDocuments = new FormData();
    let object = this.docsForm.get('documents').value;
    //console.log(object);
    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        const documents = object[key];
        let upload = object[key]['upload'];
        if (upload === 'yes') {
          for (const keyd in documents) {
            if (Object.prototype.hasOwnProperty.call(documents, keyd)) {
              const value = documents[keyd];

              if (keyd == 'file') {
                this.docsFormDocuments.set('file_url', value);
              } else if (keyd == 'name') {
                this.docsFormDocuments.set('name', value);
                this.docsFormDocuments.set('case_id', this.case.id);
              }
            }
          }
          // console.log( this.docsFormData);
          this.DocumentServiceData();
        }
      }
    }
  }

  DocumentServiceData() {
    this.lawyerService.upload_document_cases(this.docsFormDocuments).subscribe(
      (Response) => {
        console.log(Response);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  //

  addPhase(fase?: any): void {
    this.fase += 1;
    const phases = this.formBuilder.group({
      phase: [fase?.id ?? this.fase, [Validators.required]],
      description: [fase ? fase.phase : '', [Validators.required]],
      percent: [fase ? fase.percent : '', [Validators.required]],
    });

    this.phases.push(phases);

    this.docsForm.controls.phases_number.patchValue(this.phases.length);
  }

  removePhase(): void {
    const index = this.phases.length;
    this.phases.removeAt(index - 1);
    this.docsForm.controls.phases_number.patchValue(this.phases.length);
  }

  alertDelete(value) {
    Swal.fire({
      title: 'Atención!',
      text: 'Seguro que desea eliminar el caso?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#212F54',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si eliminar!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.lawyerService.deleteCases(value).subscribe(
          async (response) => {
            // deleteCases
            Swal.fire({
              title: 'Eliminado!',
              text: 'El registro de ha eliminado',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#212F54',
              confirmButtonText: 'Ok',
            }).then((result) => {
              if (result.isConfirmed) {
                this.loadListCases();
              }
            });
          },
          (error) => { }
        );
      }
    });
  }

  upload() { }
  upload2() { }
  upload3() { }

  showCase(modal, item) {
    this.docsForm.reset()
    this.isEdit = true;
    this.idCase = item.id;

    this.selected = false;
    this.editSelectedFilename = {
      current: null,
      old: null,
    };

    this.order_id = this.idCase;

    this.refreshComment();
    this.loadDataHistory();

    this.lawyerService.showCases(item.id).subscribe(
      async (response) => {
        let data = response.data;
        //console.log(data);
        this.case = { ...data };
        data['phases'] == 'null'
          ? (data['phases'] = [])
          : (data['phases'] = JSON.parse(data['phases']));
        this.extraFields = this.case?.type == 'Proceso';
        this.actual_phase = data['actual_phase'] ?? null;
        data.external_quote_file = typeof data.external_quote_file == 'string' ? data.external_quote_file : null;
        const contraparte = data['contraparte']?.length ? data['contraparte'] : [];
        const apoderados = data['apoderados']?.length ? data['apoderados'] : [];

        delete data['contraparte'];
        delete data['apoderados'];

        this.docsForm.patchValue(data);
        console.log('debug', data);

        this.LoadInput(data);
        this.LoadPhasesEdit(data['phases_array']);

        this.removeContraparte(0);
        this.removeApoderado(0);

        contraparte.forEach((c:string) => {
          console.log('contra',c);
          this.addContraparte(c);
        });

        apoderados.forEach((a:string) => {
          this.addApoderado(a);
        })
        // this.LoadFileDocument(data["case_documents"]);

        this.loadExpense(this.idCase);

        this.modal = this.modalService.open(modal, {
          backdrop: 'static',
          size: 'lg',
        });
      },
      (error) => { }
    );
    let param = 'case_id=' + item.id + '&page=1';
    this.lawyerService.get_document_cases(param).subscribe(
      async (response) => {
        let data = response.data;
        //  console.log(data);
        this.LoadFileDocument(data);
      },
      (error) => { }
    );
  }

  LoadInput(data: any) {
    this.docsForm.get('name').setValue(data['case_name']);
    this.docsForm.get('lawyer_client_id').setValue(data['client']['id']);
    this.docsForm.get('lawyer_entity_id').setValue(data['entity']['id']);
    const [day, month, year] = data['init_date'].split('-');
    this.docsForm
      .get('init_date')
      .setValue(new NgbDate(parseInt(year), parseInt(month), parseInt(day)));
  }

  LoadPhasesEdit(data: any[]) {
    data.forEach(phase => {
      this.addPhase(phase);
    });
    // let object = data;
    // if (object != null) {
    //   for (const key in object) {
    //     if (Object.prototype.hasOwnProperty.call(object, key)) {
    //       const element = object[key];
    //       this.addPhase(element);
    //     }
    //   }
    // }
  }

  onDeSelectFile(inputElement) {
    if (this.editSelectedFilename.old !== null) {
      this.docsForm.get('external_quote_file').setValue(this.editSelectedFilename.old);
    } else {
      this.docsForm.get('external_quote_file').setValue(null);
      inputElement.value = '';
    }

    this.selected = false;
  }

  onFileSelect(e, name) {
    if (e.target.files && e.target.files.length) {
      const [file] = e.target.files;
      const fileName = file.name;

      switch (name) {
        case 'external_quote_file':
          this.editSelectedFilename.current = fileName;
          this.selected = true;

          if (typeof this.docsForm.get('external_quote_file').value == 'string') {
            this.editSelectedFilename.old = this.docsForm.get('external_quote_file').value;
          }

          this.docsForm.get('external_quote_file').setValue(file);
          break;
        case 'documents':
          // this.docsForm.get('documents').setValue(file)
          this.addDocuments(file, true, null, fileName);
          break;
      }
    }
  }

  addDocuments(file, upload: boolean, id = null, fileName): void {
    this.docum++;
    const document = this.formBuilder.group({
      file: [file, [Validators.required]],
      upload: [upload ? 'yes' : 'no', [Validators.required]],
      name: [fileName, [Validators.required]],

      id: [upload ? '' : id, [Validators.required]],
    });

    this.documents.push(document);
  }

  ShowPdf(document, upload) {
    if (upload == 'no') {
      window.open(document['file'], '_blank');
    } else {
      this.viewpdf(document['file'], document['name']);
    }
  }

  viewpdf(downloadedFile, name) {
    // create a download anchor tag
    var downloadLink = document.createElement('a');
    downloadLink.target = '_blank';
    downloadLink.download = name + '.pdf';

    // convert downloaded data to a Blob
    var blob = new Blob([downloadedFile], { type: 'application/pdf' });

    // create an object URL from the Blob
    var URL = window.URL;
    var downloadUrl = URL.createObjectURL(blob);

    // set object URL as the anchor's href
    downloadLink.href = downloadUrl;

    // append the anchor to document body
    document.body.appendChild(downloadLink);

    // fire a click event on the anchor
    downloadLink.click();

    // cleanup: remove element and revoke object URL
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(downloadUrl);
  }

  LoadFileDocument(data) {
    let object = data;

    if (object) {
      for (const key in object) {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
          const elements = object[key];

          this.addDocuments(
            elements['file_url'],
            false,
            elements['id'],
            elements['name']
          );
        }
      }
    }
  }

  DeleteDocument(id: string, index: number) {
    Swal.fire({
      title: 'Atención!',
      text: 'Seguro que desea eliminar el documento?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#212F54',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si eliminar!',
    }).then((result) => {
      if (result.isConfirmed) {
        if (id != '') {
          this.lawyerService.remove_document_cases(id).subscribe(
            async (response) => {
              let message = response.message;
              Swal.fire({ text: message, icon: 'success', confirmButtonColor: '#212F54' });
            },
            (error) => { }
          );
        } else {
          this.documents.removeAt(index);
        }
      }
    });
  }

  loadExpense(id: string) {
    this.lawyerService.indexCaseExpenses(id, 1).subscribe((response) => {
      this.dataExpense = response.data;
    });
  }

  addExpense() {
    this.isEditExpense = false;
    this.pageExpense = true;
    this.expenseEditId = null;
    this.createFormExpense();
    this.submittedE = false;
  }

  private createFormExpense(): void {
    this.formExpense = this.formBuilder.group({
      date: ['', Validators.required],
      amount: ['', Validators.required],
      description: ['', Validators.required],
      expense_reference: ['', Validators.required],
      expense_reference_file: [''],
    });
  }

  closedExpense() {
    this.createFormExpense();
    this.pageExpense = false;
    this.submittedE = false;
    this.isEditExpense = false;
    this.expenseEditId = null;
    this.expenseFile = null;
    this.fileExpense = null;
  }

  editExpense(expense:any) {
    this.isEditExpense = true;
    this.pageExpense = true;
    this.createFormExpense();
    this.expenseEditId = expense.id;
    this.formExpense.patchValue({
      date: this.formatter.parse(expense.discharge_day),
      amount: expense.amount,
      description: expense.description,
      expense_reference: expense.expense_reference,
    })
    this.submittedE = false;
  }
  onSubmitExpense() {
    this.submittedE = true;
    if (this.formExpense.valid) {
      const date = this.formatter.format(this.formExpense.value.date);

      let formData = new FormData();
      formData.append('case_id', this.idCase);
      formData.append('date', date);
      formData.append('amount', this.formExpense.value.amount);
      formData.append('description', this.formExpense.value.description);
      formData.append(
        'expense_reference',
        this.formExpense.value.expense_reference
      );

      if (this.fileExpense) {
        formData.append('expense_reference_file', this.fileExpense);
      }

      if (this.expenseEditId && this.isEditExpense) {
        this.lawyerService.updateCaseExpenses(this.expenseEditId,formData).subscribe(
          (response) => {
            Swal.fire({ text: response.message, icon: 'success', confirmButtonColor: '#212F54' });
            this.closedExpense();
            this.loadExpense(this.idCase);
          },
          (error) => {
            console.log(error);
          }
        );
      }else {
        this.lawyerService.addCaseExpenses(formData).subscribe(
          (response) => {
            Swal.fire({ text: response.message, icon: 'success', confirmButtonColor: '#212F54' });
            this.closedExpense();
            this.loadExpense(this.idCase);
          },
          (error) => {
            console.log(error);
          }
        );
      }
    }
  }

  clearExpenseFile() {
    this.expenseFile = null;
    //this.formGroup.get('expense_reference_file').reset(); // Asegúrate de resetear el control
  }

  onFileExpense(e) {
    if (e.target.files && e.target.files.length) {
      this.fileExpense = e.target.files[0];
      this.expenseFile = e.target.files[0];
    }
  }

  deleteExpense(id: string) {
    Swal.fire({
      title: 'Atención!',
      text: 'Seguro que desea eliminar este gasto?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#212F54',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si eliminar!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.lawyerService.deleteCaseExpenses(id).subscribe(
          (response) => {
            Swal.fire({ text: response.message, icon: 'success', confirmButtonColor: '#212F54' });
            this.closedExpense();
            this.loadExpense(this.idCase);
          },
          (error) => {
            console.log(error);
          }
        );
      }
    });
  }

  clearFilter() {
    this.loadListCases();
    this.idCaseSearch = '';
  }

  addComment() {
    this.lawyerService
      .addCommentCase({
        case_id: this.order_id,
        comment: this.comment,
      })
      .subscribe((response) => {
        let data = response.data;
        this.comment = '';
        this.refreshComment();
        Swal.fire({
          title: 'Registro exitoso',
          text: 'Se ha registrado un comentario',
          icon: 'success',
          confirmButtonColor: '#212F54'
        });
      });
  }

  deleteComment(id: number) {
    this.lawyerService.deleteCommentCase(id).subscribe((response) => {
      let data = response.data;
      this.comment = '';
      this.refreshComment();
      Swal.fire({ title: 'Comentario eliminado', text: '', icon: 'success', confirmButtonColor: '#212F54' });
    });
  }

  refreshComment() {
    this.comment = '';
    this.loadDataComment();
  }

  loadDataComment(page = 1) {
    this.lawyerService
      .listCommentCase(page, this.order_id)
      .subscribe((response) => {
        this.listComment = response.data;
        this.dataPaginationComment = response.meta.page;
      });
  }

  filters = {
    caseName: null,
    statusFilter: "",
    branchFilter: "",
    clientName: null,
    fromData: null,
    toDate: null,
    typeFilter: "",
  };

  filtersDate = {
    fromData: null,
    toDate: null,
  };

  public onFilterCaseChange($event: any, key: string): void {
    if (key == 'fromData' || key == 'toDate') {
      const date = new Date($event.year, $event.month - 1, $event.day);
      $event = date.toISOString().split('T')[0];
    }

    clearTimeout(this.searchFormTimeout);

    this.searchFormTimeout = setTimeout(() => {
      this.filters[key] = $event;
      this.updateUrl();
      this.loadListCases();
    }, 800);

  }

  updateUrl() {
    const queryParams = {};
    for (const filterKey in this.filters) {
      if (
        this.filters.hasOwnProperty(filterKey) &&
        this.filters[filterKey] !== null
      ) {
        queryParams[filterKey] = this.filters[filterKey];
      }
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  isString(value) {
    return typeof value === 'string';
  }

  loadDataHistory(page = 1) {
    this.lawyerService.caseDetailHistory(this.order_id, page).subscribe(
      reponse =>{
        console.log('debug-history', reponse)
        this.dataHistory = reponse?.data;
        this.dataHistoryPagination = reponse?.meta?.page ?? 1;
      }
    );
  }

  openModalDetailHistory(detailModal: any, historyData: any) {
    let data = []
    const keys = Object.keys(historyData.changes);
    keys.forEach((key, idx) => {
      data[idx] = {
        // key: key,
        ...this.formatChangeHistory(historyData.changes[key])
      }
    });

    this.selectedHistoryChanges = data
    this.modal = this.modalService.open( detailModal, { backdrop: 'static', size: 'md', centered: true } );
  }

  formatChangeHistory(change: any) {
    let oldValue = change.old
    let newValue = change.new
    const field_name = change.field_name
    if (field_name) {
      switch (field_name) {
        case 'phases':
          oldValue = oldValue?.length && typeof oldValue == 'string' ? JSON.parse(oldValue) : oldValue
          newValue = newValue?.length && typeof newValue == 'string' ? JSON.parse(newValue) : 'jose'
          break;

        default:
          break;
      }
    }
    return {
      field_name,
      old: oldValue,
      new: newValue,
      key: change.key
    }
  }
}
