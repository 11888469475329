import { Component, OnInit } from '@angular/core';
import { ClientsService } from '../../../shared/services/clients.service';
import { NgbModal, NgbNav, NgbNavItem, NgbNavLink, NgbNavContent } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { TitlePageService } from 'src/app/shared/services/title-page.service';
import { environment } from '../../../../environments/environment'; 

@Component({
  selector: 'app-foll-services',
  templateUrl: './foll-services.component.html',
  styleUrls: ['./foll-services.component.css']
})
export class FollServicesComponent implements OnInit {
  modal: any;
  active = 1;
  IsPublic = 0;
  documentListForService: any;
  docsFormData!: FormData;
  NameLawyer = ''; namePhaseCurrent;
  id_order;
  listTramAct = [];
  listTramC = [];
  ListDocument = []; detailService;
  assetUrl = environment.assetsUrl;
  currentItem: any;
  modalPay: any;
  modalDetail: any;
  info: unknown;
  bill:any = {
    tramit_number: "5270",
    tramit_name: "Divorcio",
    completed_phases: "Fase 1, Fase 2",
    current_phase: "Fase 3",
    amount_payable: "208$",
    tax: "20$",
    full_payment: "228$"
  }


  page; dataPagination;
  page2; dataPagination2;
  page3; dataPagination3;
  constructor(
    private ClientsService: ClientsService,
    private modalService: NgbModal,
    titlePageService: TitlePageService
  ) {
    titlePageService.titlePageObservableData = { name: 'Seguimiento de servicios'};
    this.page = 1;
    this.page2 = 1;
    this.page3 = 1;
  }

  ngOnInit(): void {
    this.docsFormData = new FormData();
    this.getActivos('Activo', this.page);
    this.getCulminados('Culminado', this.page2);
  }

  getActivos(type = 'Activo', page) {
    this.ClientsService.geMonitoringService('Activo', page).subscribe(response => {
      this.listTramAct = response.data;
      this.dataPagination = response.meta.page;
    });
  }
  getCulminados(type = 'Culminado', page) {
    this.ClientsService.geMonitoringService('Culminado', page).subscribe(response => {
      this.listTramC = response.data;
      console.log(this.listTramC);
      this.dataPagination2 = response.meta.page;
    });
  }

  loadPage(page) {
    this.listTramAct = [];
    this.getActivos('Activo', page);
  }

  loadPage2(page) {
    this.listTramC = [];
    this.getCulminados('Culminado', page);
  }

  openDetail(serviceModal: any, id_order, item, page): void {



    this.id_order = id_order;
    this.ClientsService.getDetailMonitoringService(id_order).subscribe(response => {
      this.detailService = {...response.data};

      let valid_payments = this.detailService.order_payments.filter(e=> e.status == "Aprobado")

	  console.log('debValid', valid_payments, item)

      if(valid_payments.length > 0){

        this.getDocumentsForService(this.id_order, page);

        let control = this.detailService.phases.filter( phase =>
          phase.phase == this.detailService.current_phase
        )
        this.namePhaseCurrent = control[0].description

        this.openModal(serviceModal);
      }else{
        this.alrtNoPayment(item);
      }


    });
    //  this.GetDataDetail(id_order);


  }

  alrtNoPayment(data) {


    let message = data.payment_status


    if(message == "No existen pagos" || message == "Faltan pagos por realizar" ){

      message = "En espera del pago del Trámite"

      let valid_approved_lawyer = data.order_lawyer.filter(e=> e.approved_by_lawler == 1)

      let valid_payments = data.order_payments.filter(e=> e.status != "Aprobado")

      console.log(valid_approved_lawyer)
      console.log(valid_payments)



      if(valid_approved_lawyer.length < 1) message = "En espera de aprobación del abogado"
      else if(valid_payments.length > 0) message = "En espera de aprobación de su pago"


    }

    console.log(message)

    Swal.fire({
      icon: 'warning',
      text: ' ',
      title: message,
    })




  }

  private openModal(serviceModal: any): void {

    this.modal = this.modalService.open(serviceModal, { backdrop: 'static', size: 'lg' });
  }

  LoadFileDocument() {
    let object = this.documentListForService;
    if (object) {
      for (const key in object) {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
          const elements = object[key];
          let index = parseInt(key) + 1;
          let item = {
            date: elements['created_date'],
            name: elements['name'],
            //  user : elements['user']['full_name'],
            //   name : 'Document ' + String(index),
            is_public: 0,
            order_id: this.detailService.id,
            file_url: elements['file_url'],
            upload: 'no'
          }
          this.ListDocument.push(item);
        }
      }
    }

  }

  onFileSelect(e: any): void {
    const reader = new FileReader();
    if (e.target.files && e.target.files.length) {
      const [file] = e.target.files;
      let name = file['name'].split('.');

      let item = {
        date: Date.now(),
        user: this.NameLawyer,
        name: name[0],
        is_public: 0,
        file_url: file,
        order_id: this.detailService.id,
        'includes[]': 'user',
        upload: 'yes'
      }
      this.ListDocument.push(item);
      this.IsPublic = 0;

    }


  }

  ShowPdf(document, upload) {
    if (upload == 'no') {
      window.open(document['file_url'], '_blank');
    } else {
      this.viewpdf(document['file_url'], document['name']);
    }
  }

  viewpdf(downloadedFile, name) {
    var downloadLink = document.createElement('a');
    downloadLink.target = '_blank';
    downloadLink.download = name + '.pdf';
    var blob = new Blob([downloadedFile], { type: 'application/pdf' });
    var URL = window.URL;
    var downloadUrl = URL.createObjectURL(blob);
    downloadLink.href = downloadUrl;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(downloadUrl);
  }

  DocumentServiceData() {
    this.ClientsService.loadDocuments(this.docsFormData).subscribe(
      Response => {
        console.log(Response);
      },
      error => {
        console.log(error);
      }
    );
  }

  async LoadDocument() {
    let object = this.ListDocument;
    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        const documents = object[key];
        let upload = object[key]['upload'];
        if (upload === 'yes') {
          for (const keyd in documents) {
            if (Object.prototype.hasOwnProperty.call(documents, keyd)) {
              const value = documents[keyd];
              if (keyd != 'date' && keyd != 'user') {
                this.docsFormData.set(keyd, value);
              }
            }
          }
          this.DocumentServiceData();
        }
      }
    }
  }

  CancelModal() {
    this.modalService.dismissAll();
    this.ListDocument = [];
  }

  async UploadInfo() {
    try {
      await this.LoadDocument();

    } catch (error) {
      console.log(error);
    } finally {
      // console.log('finish');
      // this.modalService.dismissAll();
      this.ListDocument = [];
      Swal.fire({ text: 'Actualizacion exitosa', icon: 'success' });
	  this.CancelModal();
    }
    this.active = 1;
    this.getDocumentsForService(this.id_order, this.page3);

  }

  async UploadInfo2() {
    try {
      await this.LoadDocument();

    } catch (error) {
      console.log(error);
    } finally {
      // console.log('finish');
      // this.modalService.dismissAll();
      this.ListDocument = [];
     // Swal.fire({ text: 'Actualizacion exitosa', icon: 'success' });
	  this.CancelModal();
    }
    this.active = 1;
    this.getDocumentsForService(this.id_order, this.page3);

  }

  ReturnNamePhase(id_phase) {
    const array1 = this.detailService.phases;
    const number = id_phase.toString();
    const namePhase = array1.find(element => element.phase === number);
    console.log(namePhase)
    this.namePhaseCurrent = namePhase;
  }

  getDocumentsForService(id_order, page) {
    this.ClientsService.getDocumentsForService(id_order, page).subscribe(
      Response => {
        this.documentListForService = Response.data;
        this.dataPagination3 = Response.meta.page;
        //console.log(this.documentListForService);
        this.LoadFileDocument();
      },
      error => {
        console.log(error);
      }
    );
  }

  loadPage3(page) {
    this.page3 = page;
    this.documentListForService = [];
    this.ListDocument = [];
    this.getDocumentsForService(this.id_order, this.page3);
  }

  cancelService(order_id)
  {
    Swal.fire({
      title: 'Atención!',
      text: "¿Seguro que desea cancelar?",
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true
     }).then((result) => {
       if (result.isConfirmed) {

          let data ={
            order_status_id:4
          }
    
          this.ClientsService.cancelOrder(order_id,data).subscribe(response => {
            this.getActivos('Activo', this.page);
            this.getCulminados('Culminado', this.page2);
          });
       }
     }
    );
  }

	handlePaymentEvent(evt) {
		this.getActivos('Activo', this.page);
		this.getCulminados('Culminado', this.page2);
	}

  openModalPay(detailModal: any, item) {
	let valid_approved_lawyer = item.order_lawyer.filter(e=> e.approved_by_lawler == 1)

	if(valid_approved_lawyer?.length == 0){
		Swal.fire({text: 'El abogado no ha aprobado la orden', icon: 'error'});
		return;
	}
    this.ClientsService.getUpcomingPayment(item.id).subscribe(
      response => {
        this.currentItem = response.data;
        this.modal = this.modalService.open( detailModal, { backdrop: 'static', size: 'lg' } );
        this.modalDetail = detailModal;

        let phaseCurrency = this.currentItem.current_phase.split("-");
        phaseCurrency = phaseCurrency.shift();
        let  phases: Array<any> = this.currentItem.phases ;

        this.bill.completed_phases = ""

        for (let index = 0; index < phases.length; index++) {
          const phase = phases[index];

          console.log(phaseCurrency)
          console.log(phase.phase)

          if(phaseCurrency == phase.phase){
            break;
          }

          if(this.bill.completed_phases == "")
          this.bill.completed_phases = phase.description
          else
          this.bill.completed_phases += ", " + phase.description


        }

      }
    );
  }

  pagar(modal, data) {
    
    if(data.phase_to_paid.pending == 0)
    {
      Swal.fire({
        icon: 'info',
        text: 'No hay monto pendiente por pagar.',
      })

      return
    }

    this.ClientsService.getNextPayment(data.id).subscribe(
      response => {

        this.modal.dismiss();
        let payment = response.data.phase_to_paid
        this.info = {
          amount: payment.total,
          order: data.id,
          description: payment.description,
          phase_info: payment
        }
        this.modalPay = this.modalService.open(modal, { backdrop: 'static', size: 'md' });
    });

  }

  closerModalPay() {
    //cerrar el modal y recargar
    this.modalPay.dismiss();
    this.modal = this.modalService.open( this.modalDetail, { backdrop: 'static', size: 'md' } );
  }


}
