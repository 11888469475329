import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { NgbModal, ModalDismissReasons, NgbNav, NgbNavItem, NgbNavLink, NgbNavContent} from '@ng-bootstrap/ng-bootstrap';
import { BranchesLaw } from '../../../shared/interfaces/common';
import { AdminService } from '../../../shared/services/admin.service';
import { MyDocumentsService } from 'src/app/shared/services/my-documents.service';
import { forkJoin, from } from 'rxjs';
import Swal from 'sweetalert2';
import { TitlePageService } from 'src/app/shared/services/title-page.service';

@Component({
  selector: 'app-my-documents-marketplace',
  templateUrl: './my-documents-marketplace.component.html',
  styleUrls: ['./my-documents-marketplace.component.css']
})
export class MyDocumentsMarketplaceComponent implements OnInit {
  List = [];
  active = 1;
  branchs: BranchesLaw[] = [];
  services=[];
  branchID= 1;
  fileName = 'Seleccione archivo';
  docsSelected = {};
  orderSelected = 0;
  private _docFile = '';
  formDoc: FormGroup;
  docFormData: FormData;
  modal: any;
  constructor(
    private adminService: AdminService, 
    private fb: FormBuilder,
    private modalService: NgbModal,
    private myDocumentsService: MyDocumentsService,
    titlePageService: TitlePageService
  ) { 
    titlePageService.titlePageObservableData = { name: 'Mis Documentos'};
    forkJoin( [  this.adminService.listBranchs(), this.myDocumentsService.typeService() ] )
      .subscribe( ( [ branchsResponse, serviceResponse ] ) => {
        this.branchs = [ ...branchsResponse.data ];
        this.services = [ ...serviceResponse.data ]
      } );

      this.formDoc = this.fb.group({
        file_url: ['', [Validators.required]],
      });
      this.loadDoc(this.branchID,this.active)
  }

  get d(): any { return this.formDoc.controls; } 

  onDocChange( e ): void {
    const reader = new FileReader();
    if ( e.target.files && e.target.files.length ) {
      const [ file ] = e.target.files;
      this.fileName = file.name;
      reader.readAsDataURL( file );
      reader.onload = () => {
        this._docFile = reader.result as string;
      };
    }
  }

  ngOnInit(): void {
  }

  openUploadDocModal( uploadDocModal: any , order): void {
    this.orderSelected = order;
    this.modal = this.modalService.open( uploadDocModal );
  }

  onSubmit() : void {

    if ( this.formDoc.valid ) {

      const formDoc = document.getElementById('formDoc') as HTMLFormElement;
      this.docFormData = new FormData(formDoc);
      this.docFormData.append('order_id', this.orderSelected.toString() );
      this.docFormData.append('name', this.fileName);
      this.docFormData.append('is_public', '1');

      this.myDocumentsService.uploadDocumentsLaywer(this.docFormData).subscribe( response => {
          Swal.fire( { text: 'Registro de documento exitoso', icon: 'success' } );
          this.fileName = '';
          this.loadDoc(this.branchID,this.active)
          this.modal.dismiss();
      });

    } else {
      Swal.fire( { text: 'Debe cargar un archivo', icon: 'success' } );

    }
 
  }

  loadDoc(id1: number,id2: number) {
    this.myDocumentsService.myDocumentsBS(id1,id2).subscribe( response => {
      this.List = [ ...response.data ];
      console.log(response.data);
    } );
  }

  changeBranch(event){
    //console.log('id service '+this.active,'  id rama '+event);
    this.loadDoc(event,this.active);
  }

  changeService(id){
    this.active = id;
    //console.log('id service '+id,'  id rama '+this.branchID);
    this.loadDoc(this.branchID,id);
  }

  open(content, order): void {
    console.log(order);
    this.docsSelected = order.orderDocuments;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
    /*if (order.is_payment) {
      
    } else {
      this.alrtNoPayment(order);
    }*/
  }

  alrtNoPayment(data) {
    Swal.fire({
      icon: 'warning',
      text: ' ',
      title: data.payment_status,
    })
  }

}
