<div class="content-body d-flex align-items-center">
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="col-xl-12 col-sm-12 text-center">
        <h3>Seguimiento de servicios</h3>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="px-3 px-lg-4 border-0">
          <ul
            ngbNav
            #nav="ngbNav"
            [activeId]="active"
            class="nav-tabs nav nav-pills nav-fill"
          >
            <li [ngbNavItem]="1">
              <a ngbNavLink>Activos</a>
              <ng-template ngbNavContent>
                <div class="py-4">
                  <div *ngIf="listTramAct.length > 0">
                    <div class="card card-tar" *ngFor="let item of listTramAct">
                      <div class="card-header border-0 pb-0">
                        <h5 class="card-title">{{ item.service?.name }}</h5>
                      </div>
                      <div class="card-body">
                        <strong>Abogado asesor: </strong>
                        <span
                          *ngIf="item.lawyer !== null && item.lawyer !== []"
                        >
                          {{ item.lawyer?.user?.full_name }}
						</span
                        >
                        <span *ngIf="item.lawyer === null || item.lawyer === []"
                          >Asignando ...
                        </span><br>
                        <strong>Fecha: </strong>
                        <span>{{item.created_at}}</span><br>
                        <strong>Monto: </strong>
                        <span>{{item.total | currency}}</span><br><br>
                        <strong>Descripción: </strong>
                        <span>{{item.description}}</span><br><br>
                        <strong>Estado: </strong>
                        <span>{{item.status.name}}</span>
                      </div>
                      <div class="card-footer border-top pt-3 bg-light">
                        <div class="d-flex justify-content-between align-items-center gap-2">
                          <!-- Grupo de Acciones Principales -->
                          <div class="d-flex gap-2">
                           
                            <button type="button"
                                  *ngIf="['En proceso', 'Pendiente'].includes(item.status.name)"
                                  class="btn btn-primary btn-sm"
                                  style="margin-right: 10px;"
                                  (click)="openDetail(serviceModal, item.id, item, page)"
                                  title="Ver detalles del servicio"
                                  aria-label="Ver detalles">
                            <i class="bi bi-info-circle me-1"></i>
                            Ver Detalles 
                           </button>
                      
                            <!-- Botón Pagar -->
                            <button type="button" 
                                    *ngIf="['En proceso', 'Pendiente'].includes(item.status.name)"
                                    class="btn btn-success btn-sm"
                                    (click)="openModalPay(detailModal, item)"
                                    title="Realizar pago del servicio"
                                    aria-label="Realizar pago">
                              <i class="bi bi-credit-card me-1"></i>
                              Realizar Pago
                            </button>
                          </div>

                          <button type="button" 
                          *ngIf="item.status.name != 'Cancelado'"
                          class="btn btn-outline-danger btn-sm"
                          (click)="cancelService(item.id)"
                          [disabled]="isCancelling"
                          title="Cancelar servicio"
                          aria-label="Cancelar servicio">
                    <i class="bi bi-x-circle me-1"></i>
                    <span *ngIf="!isCancelling">Cancelar</span>
                    <span *ngIf="isCancelling" aria-hidden="true">Cancelando...</span>
                  </button>
                        </div>
                      </div>

                    </div>
                    <div class="row" *ngIf="dataPagination.lastPage > 1">
                      <div class="col-auto">
                        <ngb-pagination
                          [pageSize]="12"
                          (pageChange)="loadPage($event)"
                          [collectionSize]="dataPagination?.total"
                          [maxSize]="5"
                          [(page)]="page"
                          aria-label="Default pagination"
                        ></ngb-pagination>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="listTramAct.length === 0" class="custom-no-item">
                    <h5>No cuentas con servicios activos</h5>
                  </div>
                </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink>Culminados</a>
              <ng-template ngbNavContent>
                <div class="py-4">
                  <div *ngIf="listTramC.length > 0">
                    <div class="card card-tar" *ngFor="let item of listTramC">
                      <div class="card-header border-0 pb-0">
                        <h5 class="card-title">{{ item.service?.name }}</h5>
                      </div>
                      <div class="card-body">
                        <strong>Abogado asesor: </strong>
                        <span
                          *ngIf="item.lawyer !== null && item.lawyer !== []">
                          {{ item.lawyer?.user?.full_name }}
                        </span>
                        <span *ngIf="item.lawyer === null || item.lawyer === []"
                          >Asignando ...
                        </span>
                        
                        <br>
                        <strong>Fecha: </strong>
                        <span>{{item.created_at}}</span><br>
                        <strong>Monto: </strong>
                        <span>{{item.total | currency}}</span><br>
                        <br>
                        <strong>Descripción: </strong>
                        <span>{{item.description}}</span>
                      </div>


                      <!--<div class="card-footer border-0 pt-0">
                        <a class="card-link float-right"
                          (click)="openDetail(serviceModal, item.id, item, page2)">
                          <i class="icon-list custom-link"></i> Detalle
                        </a>                      
                      </div>-->

                      <div class="card-footer border-top pt-3 bg-light">
                        <div class="d-flex justify-content-between align-items-center gap-2">
                          <div class="d-flex gap-2">
                           
                            <button type="button"
                                  class="btn btn-primary btn-sm"
                                  (click)="openDetail(serviceModal, item.id, item, page2)"
                                  title="Ver detalles del servicio"
                                  aria-label="Ver detalles">
                            <i class="bi bi-info-circle me-1"></i>
                            Ver Detalles 
                           </button>
                        </div>
                      </div>
                    </div>

                    </div>
                    <div class="row" *ngIf="dataPagination2.lastPage > 1">
                      <div class="col-auto">
                        <ngb-pagination
                          [pageSize]="12"
                          (pageChange)="loadPage2($event)"
                          [collectionSize]="dataPagination2?.total"
                          [maxSize]="5"
                          [(page)]="page2"
                          aria-label="Default pagination"
                        ></ngb-pagination>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="listTramC.length === 0" class="custom-no-item">
                    <h5>No cuentas con servicios culminados</h5>
                  </div>
                </div>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #serviceModal>
  <div class="modal-header">
    <div class="col-12">
      <div class="row">
        <div class="col-6 d-flex">
          <h4 class="modal-title" id="modal-basic-title">
            Fecha de Solicitud:
          </h4>

          <p class="ml-2 mb-0">{{ detailService?.created_at }}</p>
        </div>

        <div class="col-6 d-flex justify-content-end text-right">
          <h4 class="modal-title" id="modal-basic-title">Numero de Tramite:</h4>

          <p class="ml-2 mb-0">{{ detailService?.id }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
      <li [ngbNavItem]="1">
        <a ngbNavLink>General</a>
        <ng-template ngbNavContent>
          <div id="content_1" class="mt-4 p-3">
            <div class="row">
              <div
                class="col-6 d-flex justify-content-start align-items-center mb-2"
              >
                <label for="" class="font-weight-bold mb-0">Rama:</label>
                <p class="ml-2 mb-0">{{ detailService?.branch_law?.name }}</p>
              </div>
              <div
			  	*ngIf="detailService.type_service?.id != 1"
                class="col-6 d-flex justify-content-start align-items-center mb-2"
              >
                <label for="" class="font-weight-bold mb-0"
                  >Tipo de asesoria:</label
                >
                <p class="ml-2 mb-0">{{ detailService?.consulting_type }}</p>
              </div>

              <div
                class="col-12 d-flex justify-content-start align-items-center mb-2"
              >
                <label for="" class="font-weight-bold mb-0">Descripción:</label>
                <p class="ml-2 mb-0">{{ detailService?.description }}</p>
              </div>
              <div
                class="col-6 d-flex justify-content-start align-items-center mb-2"
              >
                <label for="" class="font-weight-bold mb-0">Entidad:</label>
                <p *ngIf="detailService?.entity !== null" class="ml-2 mb-0">
                  {{ detailService?.entity }}
                </p>
                <p *ngIf="detailService?.entity === null" class="ml-2 mb-0">
                  Sin información
                </p>
              </div>
              <div
                class="col-6 d-flex justify-content-start align-items-center mb-2"
              >
                <label for="" class="font-weight-bold mb-0"
                  >Jurisdicción:</label
                >
                <p
                  *ngIf="detailService?.jurisdiction !== null"
                  class="ml-2 mb-0"
                >
                  {{ detailService?.jurisdiction }}
                </p>
                <p
                  *ngIf="detailService?.jurisdiction === null"
                  class="ml-2 mb-0"
                >
                  Sin información
                </p>
              </div>
              <div
                class="col-6 d-flex justify-content-start align-items-center mb-2"
              >
                <label for="" class="font-weight-bold mb-0">Despacho:</label>
                <p *ngIf="detailService?.office !== null" class="ml-2 mb-0">
                  {{ detailService?.office }}
                </p>
                <p *ngIf="detailService?.office === null" class="ml-2 mb-0">
                  Sin información
                </p>
              </div>
              <div
                class="col-6 d-flex justify-content-start align-items-center mb-2"
              ></div>
              <div
                class="col-6 d-flex justify-content-start align-items-center mb-2"
              >
                <label for="" class="font-weight-bold mb-0"
                  >Fases del Tramite:</label
                >
                <span class="ml-2 mb-0">
                  <ng-container *ngFor="let phase of detailService?.phases">
                    {{ phase.description }}<br />
                  </ng-container>
                </span>
              </div>
              <div
                class="col-6 d-flex justify-content-start align-items-center mb-2"
              >
                <label for="" class="font-weight-bold mb-0"
                  >Fases actual:</label
                >
                <span class="ml-2 mb-0">
                  <ng-container>
                    {{ namePhaseCurrent}}
                  </ng-container>
                </span>
              </div>
            </div>
            <div class="row mt-2"></div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>Documentos</a>
        <ng-template ngbNavContent>
          <div id="content_2" class="mt-4 p-2">
            <div class="row">
              <div
                class="col-12 px-3 my-3 d-flex justify-content-center align-items-center"
              >
                <div
                  class="col-9 d-flex justify-content-around align-items-center alert-dark py-2 px-4 rounded mb-2"
                >
                  <label for="" class="font-weight-bold m-0"
                    >Añadir Documentos:</label
                  >
                  <div class="col-5">
                    <select
                      name=""
                      id=""
                      class="form-control"
                      [(ngModel)]="IsPublic"
                      [ngModelOptions]="{ standalone: true }"
                    >
                      <option value="true" disabled>
                        Seleccione una opcion
                      </option>
                      <option value="true">Compartido</option>
                      <option value="false">Privado</option>
                    </select>
                  </div>
                  <div class="col-2">
                    <div class="image-upload">
                      <label for="file-input">
                        <i
                          class="flaticon-381-upload-1"
                          style="font-size: 20px"
                        ></i>
                      </label>
                      <input
                        id="file-input"
                        type="file"
                        accept="application/pdf"
                        (change)="onFileSelect($event)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-12 px-3 d-flex justify-content-start align-items-center"
              >
                <div class="col-8">Ver Documentos:</div>
              </div>
              <div class="col-12 px-3 my-3 align-items-center">
                <div class="row d-flex justify-content-center">
                  <div class="col-6 mb-2" *ngFor="let document of ListDocument">
                    <div class="list-group">
                      <a
                        (click)="ShowPdf(document, document.upload)"
                        href="javascript:void(0)"
                        class="list-group-item list-group-item-action flex-column align-items-start p-2"
                      >
                        <div class="d-flex w-100 justify-content-between">
                          <h5 class="mb-1 text-capitalize">
                            {{ document.name }}
                          </h5>
                          <small>{{ document.date }}</small>
                        </div>
                        <div
                          style="width: 100%"
                          class="d-flex justify-content-end">
                        </div>
                      </a>
                    </div>
                  </div>
                  <div
                    *ngIf="documentListForService.length > 0"
                    class="col-12 custom-div-pagination justify-content-center"
                  >
                    <ngb-pagination
                      [pageSize]="12"
                      (pageChange)="loadPage3($event)"
                      [collectionSize]="dataPagination3?.total"
                      [maxSize]="5"
                      [(page)]="page3"
                      aria-label="Default pagination"
                    ></ngb-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink>Pagos</a>
        <ng-template ngbNavContent>
          <div class="mt-4 p-2" id="content_3">
            <div class="row">
              <div
                class="col-12 px-3 d-flex justify-content-start align-items-center"
              >
                <div class="col-8">Pagos Realizados:</div>
              </div>

                <div
                  class="col-6 mb-2 mt-2"
                  *ngFor="let payment of detailService?.order_payments"
                >
                  <div class="list-group">
                    <a
                      [href]="this.assetUrl + payment.reference_file"
                      target="_blank"
                      class="list-group-item flex-column align-items-start p-2"
                    >
                      <div class="d-flex w-100 justify-content-between">
                        <p class="mb-1">{{ payment.amount | currency }}</p>
                        <small>{{ payment.reference_number }}</small>
                      </div>
                    </a>
                  </div>
                </div>

            </div>
          </div>
        </ng-template>
      </li>

    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-danger"
      (click)="CancelModal()"
    >
      Cancelar
    </button>
    <button  
      type="button"
      class="btn btn-outline-primary"
      (click)="UploadInfo2()"
    >
      Aceptar
    </button>
  </div>
</ng-template>

<ng-template #detailModal>
  <div class="modal-header">
    <h4 class="modal-title">Detalle de cotización</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <fieldset>
      <p class="ml-3 mb-2"><strong>Número de Trámite: </strong>{{currentItem.service.id}}</p>
      <p class="ml-3 mb-2"><strong>Nombre de Trámite: </strong>{{currentItem.service.name}}</p>
      <p class="ml-3 mb-2"><strong>Fases Culminadas: </strong>{{bill.completed_phases}}</p>
      <p class="ml-3 mb-2"><strong>Fase Actual: </strong>{{currentItem.phase_to_paid.description}}</p>
      <p class="ml-3 mb-2"><strong>Coste total: </strong>{{currentItem.total | currency}}</p>
    </fieldset>
    <hr>
    <h5>Desglose de cotizacion</h5>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <th>Fase</th>
          <th>Descripción</th>
          <th>%</th>
          <th>ITBMS</th>
          <th>Sub-total</th>
          <th>Total</th>
        </thead>
        <tbody>
          <tr *ngFor="let item of currentItem.phases; let i=index">
              <td>{{ item.phase}}</td>
              <td>{{ item.description}}</td>
              <td>{{ item.percent}} %</td>
              <td>{{ item.ITBMS | currency}}</td>
              <td>{{ item.sub_total | currency}}</td>
              <td>{{ item.total | currency}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="form-row mt-4">
      <div class="col-12 text-center">
        <button class="btn btn-success py-2 mr-3" *ngIf="currentItem" (click)="pagar(payM, currentItem)">Pagar fase actual</button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #payM>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Métodos de Pagos</h4>
        <button type="button" class="close" aria-label="Close" (click)="closerModalPay()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <app-payment-method (payEvent)="handlePaymentEvent($event)" [data]="info"></app-payment-method>
</ng-template>
