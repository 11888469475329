<div class="content-body d-flex align-items-center">
	<div class="">
		<div class="d-grid g-style-1">
			<div class="card b-d-shadow">
                <div class="card-body p-4">
                    <h3 class="card-title">Perfil</h3>
                    <div class="profile-photo text-center">
                        <img *ngIf="user?.lawyer?.main_image" src="{{user?.lawyer?.main_image}}" class="img-c rounded-circle w-100 mw-300" [alt]="user?.full_name">
                        <svg *ngIf="!user?.lawyer?.main_image" class="img-not-found" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0S96 57.3 96 128s57.3 128 128 128zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" fill="#231f40"/></svg>
                    </div>
                    <div class="pd-2 mt-3 fs-14 d-grid g-style-4">
                        <div>
                            <p class="m-0"><b>Nombre</b></p>
                            <p class="m-0">{{user?.full_name}}</p>
                        </div>
                        <div>
                            <p class="m-0"><b>Rol</b></p>
                            <p class="m-0">{{user?.roles?.length ? user?.roles[0]?.translated_name : ''}}</p>
                        </div>
                        <div>
                            <p class="m-0"><b>Cédula</b></p>
                            <p class="m-0">{{user?.lawyer?.dni}}</p>
                        </div>
                        <div>
                            <p class="m-0"><b>Teléfono</b></p>
                            <p class="m-0">{{user?.lawyer?.phone}}</p>                            
                        </div>
                        <div>
                            <p class="m-0"><b>Dirección</b></p>
                            <p class="m-0">{{user?.lawyer?.address}}</p>
                        </div>
                    </div>
                    <button (click)="onProfile()" class="btn btn-primary btn-lg btn-block mt-3 fs-16">
                        Actualizar Perfil						
                    </button>
                </div>
            </div>
			<div class="card b-d-shadow p-4">
                <div class="card-body d-contents">
					<h4 class="card-title mb-4">Estadísticas del mes</h4>
					<div class="d-grid-1">
						<div
							(click)="navigateTo('lawyer/requests/quotes')"
							class="clickable b-d-shadow p-w-card"
						>
							<div class="d-grid-2">
								<div class="text-left">
									<p class="mb-1">Cotizaciones por aprobar</p>
									<h2>{{ data?.quote_count }}</h2>
								</div>
								<span class="">
									<i
										class="las la-hand-holding-usd f-size-36"
									></i>
								</span>
							</div>
						</div>
						<div
							(click)="
								navigateTo('lawyer/requests/consultancies')
							"
							class="clickable b-d-shadow p-w-card"
						>
							<div class="d-grid-2">
								<div class="text-left">
									<p class="mb-1">Asesorías por aprobar</p>
									<h2>{{ data?.advisory_count }}</h2>
								</div>
								<span class="">
									<i
										class="las la-clipboard-list f-size-36"
									></i>
								</span>
							</div>
						</div>
						<div
							(click)="navigateTo('lawyer/finances')"
							class="clickable b-d-shadow p-w-card"
						>
							<div class="d-grid-2">
								<div class="text-left">
									<p class="mb-1">Cuentas por Cobrar</p>
									<h2>
										${{
											data?.not_payment
												| number : "1.2-2" : "en-US"
										}}
									</h2>
								</div>
								<span class="">
									<i class="la la-dollar f-size-36"></i>
								</span>
							</div>
						</div>
					</div>
					<h4 class="card-title mb-3 mt-4">Servicios activos</h4>
					<div class="card b-d-shadow-sm m-0 o-hidden">
                        <div class="card-body p-0">
                            <div class="table-responsive recentOrderTable h-100">
                                <table class="table bg-info-hover m-0 tr-rounded table-sm verticle-middle table-responsive-md">
                                    <thead>
                                        <tr>
                                            <th scope="col">Servicio</th>
                                            <th scope="col">Solicitante</th>
                                            <th scope="col">Fecha ult. Pago</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let d of services; let i = index" class="{{ (i%2 == 0 || i==0) ? 'tr-darker' : '' }}">
                                            <td style="max-width: 300px;">{{d?.service?.name}}</td>
                                            <td>{{d?.userApplicant?.full_name}}</td>
											<td class="text-capitalize">{{ dateLastPayment(d.order_payments) | date:'dd/MM/yyyy HH:mm a' }}</td>
                                        </tr>                                   
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- <div class="row">
    <div class="col-xl-12">
        <div class="card b-d-shadow-h">
            <div class="card-body p-c-body">
                <h4 class="card-title mb-4">Próximos Eventos Agendados</h4>
                <div class="card o-hidden b-d-shadow m-0 h-auto">
                    <div class="card-body p-0">
                        <div class="table-responsive recentOrderTable">
                            <table class="table m-0">
                                <thead>
                                    <tr>
                                        <th scope="col">Evento</th>
                                        <th scope="col">Descripción</th>
                                        <th scope="col">Inicio</th>
                                        <th scope="col">Fin</th>
                                        <th scope="col">link</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngIf="data?.events.length<=0">
                                      <td colspan="5">Sin eventos agendados</td>
                                    </tr>
                                    <tr *ngFor="let d of data?.events; let i = index" class="{{ (i%2 == 0 || i==0) ? 'tr-darker' : '' }}">
                                        <td>{{d.title}}</td>
                                        <td>{{d.description}}</td>
                                        <td>{{d.date_start | date: 'd/M/yy  h:mma'}}</td>
                                        <td>{{d.date_end | date: 'd/M/yy  h:mma '}}</td>
                                        <td>
                                            <a href="{{d.url}}" target="_blank" mat-icon-button color="accent"  placement="top" ngbTooltip="Ir al evento">
                                                <mat-icon aria-hidden="false"  style="color: #212F54;">
                                                    launch
                                                </mat-icon>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <div class="row justify-content-start mt-4">
  <div class="col-xl-6 col-sm-6">
    <div class="card">

      <div class="p-3 pb-0 d-flex flex-column ">

        <label for="" class="mb-3">Porcentaje de Avance</label>



        <p><ngb-progressbar type="primary" textType="white" [value]="data?.percentage_cases_in_process" [showValue]="true"></ngb-progressbar></p>

      </div>

    </div>
  </div>
</div> -->

<!-- <div class="row justify-content-center">
  <div class="col-xl-3 col-sm-6">

    <div class="card">
      <div class="ln-dashboard-card">
        Servicios
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-sm-6">

    <div class="card">
      <div class="ln-dashboard-card">
        Solicitudes
      </div>
    </div>
  </div>
</div> -->

<!-- <div class="row justify-content-start">


  <div class="col-xl-6 col-sm-6">
    <div class="card">

      <div class="d-flex flex-column p-3">
          <label for="" class="mb-3">Porcentaje de Avance del Caso</label>

          <div class="col">
            <app-detail-pie-chart></app-detail-pie-chart>
          </div>
      </div>


    </div>
  </div>
</div> -->
