import { Component, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { TitlePageService } from 'src/app/shared/services/title-page.service';
import { DashboardService } from '../../../services/dashboard.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { User } from 'src/app/shared/interfaces/user';
import { USER, AVATAR } from 'src/app/shared/data/constants';
import { Router } from '@angular/router';
import { LawyerService } from 'src/app/shared/services/lawyer.service';

@Component( {
  selector: 'app-lawyer-marketplace',
  templateUrl: './lawyer-marketplace.component.html',
  styleUrls: [ './lawyer-marketplace.component.css' ]
} )
export class LawyerMarketplaceComponent implements OnInit {
  data: any;
  lawyerType: string;
  Datachart = {};
  Type = "Marketplace";
  parentSubject:Subject<any> = new Subject();
  user: User;
  avatar = AVATAR;
  services = [];

  constructor(
    private dashboadService: DashboardService,
	private storage: StorageService,
	private LawyerService:LawyerService,
    private router: Router,
    titlePageService: TitlePageService
  ) {
    titlePageService.titlePageObservableData = { name: 'Inicio'};
   }

 async ngOnInit() {
	this.user = this.storage.getItem(USER);
	this.GetDataServices();
   await this.getDashboardInfo();
  }

  navigateTo(url) {
	  const newUrl = new URL(window.location.href).pathname = url;
	  window.location.href = newUrl;
  }

 async getDashboardInfo() {
    this.dashboadService.get().subscribe( response => {
      this.data =  response.data.marketplace; 

      //this.CalcPorcentChart();

      // console.log( this.Datachart);
    });
  }

  onProfile() {
    this.router.navigate(['/lawyer/update-profile']);
  }

  GetDataServices(){
	if (!this.user?.lawyer?.id) return;

	const IdLawyer = 'lawyer_id='+this.user?.lawyer?.id;
	
    let filter =  IdLawyer + "&approved_by_lawler=1&is_payment=1";
      this.LawyerService.servicesactive(filter, '', null).subscribe(
        reponse =>{
          console.log(reponse)
          this.services = reponse?.data;
        }
      );
  }

	dateLastPayment(payments): string
	{
		let payment = payments[payments.length-1];        
		return payment.created_at;
	}

  CalcPorcentChart(){
      let services  = {};
      let fordata = this.data.active_services;
      let total = this.data.total_cases;
      for (const key in fordata) {
        let name = fordata[key]['branch_law_name'];
        let value = fordata[key]['active_orders'];
        if(total!=0 && value!=0){
          // 
          let result = total/fordata[key]['active_orders'];
          services[name] = result;
        }
   
      }
      this.parentSubject.next(services);
      
  }
}
