<section class="content-body d-flex align-items-center">
  <div class="container-fluid">
    <div class="">
      <div class="card-header">
        <h3>Clientes</h3>
        <div>
          Crear cliente
          <button
            mat-icon-button
            color="accent"
            aria-label="Example icon button with a menu icon"
            (click)="add(clientModal2)"
            placement="top"
            ngbTooltip="Nueva cliente"
          >
            <mat-icon
              aria-hidden="false"
              aria-label="Example home icon"
              style="color: #212f54"
            >
              add_circle_outline
            </mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card border-0">
          <div class="card-header d-block pb-0">
            <form class="">
              <div class="form-group hidden">
                <div class="input-group">
                  <input
                    name="datepicker"
                    class="form-control"
                    ngbDatepicker
                    #datepicker="ngbDatepicker"
                    [autoClose]="'outside'"
                    (dateSelect)="onDateSelection($event)"
                    [displayMonths]="2"
                    [dayTemplate]="t"
                    outsideDays="hidden"
                    [startDate]="fromDate!"
                    tabindex="-1"
                  />
                  <ng-template #t let-date let-focused="focused">
                    <span
                      class="custom-day"
                      [class.focused]="focused"
                      [class.range]="isRange(date)"
                      [class.faded]="isHovered(date) || isInside(date)"
                      (mouseenter)="hoveredDate = date"
                      (mouseleave)="hoveredDate = null"
                    >
                      {{ date.day }}
                    </span>
                  </ng-template>
                </div>
              </div>
              <div class="row justify-content-end align-items-end">
                <div class="col-6 col-md-3">
                  <div class="item-filter">
                    <label class="mb-1"><strong>Cliente</strong></label>
                    <input
                      class="form-control form-filter"
                      name="client"
                      id="client"
                      [(ngModel)]="searchFilter.name"
                    />
                  </div>
                </div>
                <div class="col-6 col-md-3">
                  <div class="item-filter">
                    <label class="mb-1"><strong>Tipo de cliente</strong></label>
                    <select
                      class="form-control form-filter"
                      name="type"
                      id="type"
                      [(ngModel)]="searchFilter.type"
                    >
                      <option selected value="">Todos</option>
                      <option [value]="type.value" *ngFor="let type of types">
                        {{ type.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-6 col-md-2">
                  <div class="form-group">
                    <label class="mb-1"><strong>Desde</strong></label>
                    <div class="input-group">
                      <input
                        #dpFromDate
                        class="form-control form-filter-calendar"
                        placeholder=""
                        name="dpFromDate"
                        [value]="formatter.format(fromDate)"
                        (input)="
                          fromDate = validateInput(fromDate, dpFromDate.value)
                        "
                      />
                      <div class="input-group-append">
                        <button
                          class="btn form-filter calendar h-100"
                          (click)="datepicker.toggle()"
                          type="button"
                        ></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-2">
                  <div class="form-group">
                    <label class="mb-1"><strong>Hasta</strong></label>
                    <div class="input-group">
                      <input
                        #dpToDate
                        class="form-control form-filter-calendar"
                        placeholder=""
                        name="dpToDate"
                        [value]="formatter.format(toDate)"
                        (input)="toDate = validateInput(toDate, dpToDate.value)"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn form-filter calendar h-100"
                          (click)="datepicker.toggle()"
                          type="button"
                        ></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <div class="input-group">
                      <button
                        class="btn btn-primary btn-filter mr-2"
                        (click)="search()"
                      >
                        <i class="fa fa-search"></i>
                      </button>
                      <button
                        class="btn btn-primary btn-filter"
                        (click)="clearFilter()"
                      >
                        <i class="fa fa-refresh"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="card-body">
            <ng-container *ngIf="List.length; else nodata">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Nº</th>
                      <th scope="col">Nombre Completo</th>
                      <th scope="col">Email</th>
                      <th scope="col">Fecha de registro</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let client of List; let i = index">
                      <td>{{ i + 1 }}</td>
                      <td>
                        {{
                          client.type === "Natural"
                            ? client.data_client.full_name
                            : client.data_client.business_name
                        }}
                      </td>
                      <td>{{ client.data_client.email }}</td>
                      <td>{{ client.create_date }}</td>
                      <td>
                        <button
                          mat-icon-button
                          color="accent"
                          (click)="
                            openModalInfo(client.id, client.type, clientModal)
                          "
                          placement="top"
                          ngbTooltip="Ver Info"
                        >
                          <mat-icon aria-hidden="false" style="color: #212f54">
                            visibility
                          </mat-icon>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  class="col-12 custom-div-pagination justify-content-center"
                >
                  <ngb-pagination
                    [pageSize]="12"
                    (pageChange)="loadPage($event)"
                    [collectionSize]="dataPagination?.total"
                    [maxSize]="5"
                    [(page)]="page"
                    aria-label="Default pagination"
                  ></ngb-pagination>
                </div>
              </div>
            </ng-container>
            <ng-template #nodata>
              <div class="alert alert-warning" role="alert">
                <strong>No hay data disponible</strong>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #clientModal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Cliente {{ selectedType }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ul
      ngbNav
      #nav="ngbNav"
      [activeId]="active"
      class="nav-tabs nav nav-pills nav-fill"
    >
      <li [ngbNavItem]="1">
        <a ngbNavLink>Información Básica</a>
        <ng-template ngbNavContent>
          <div class="p-4">
            <fieldset
              *ngIf="selectedType === 'Natural' || selectedType === 'Jurídico'"
            >
              <legend>General</legend>
              <p class="ml-3 mb-2">
                <strong>Nombre Completo: </strong
                >{{ selectedClient.client_name }}
              </p>
              <ng-container *ngIf="selectedType === 'Natural'">
                <p class="ml-3 mb-2">
                  <strong>Cédula de Identidad: </strong
                  >{{ selectedClient.data_client?.dni }}
                </p>
                <p class="ml-3 mb-2">
                  <strong>Email: </strong
                  >{{ selectedClient.data_client?.email }}
                </p>
                <p class="ml-3 mb-2">
                  <strong>Número de Teléfono: </strong
                  >{{ selectedClient.data_client?.phone }}
                </p>
              </ng-container>
              <ng-container *ngIf="selectedType === 'Jurídico'">
                <p class="ml-3 mb-2">
                  <strong>Razón Social: </strong
                  >{{ selectedClient.data_client?.business_name }}
                </p>
                <p class="ml-3 mb-2">
                  <strong>Razón Comercial: </strong
                  >{{ selectedClient.data_client?.business_reason }}
                </p>
              </ng-container>
            </fieldset>

            <fieldset
              *ngIf="selectedType === 'Natural' || selectedType === 'Jurídico'"
            >
              <legend>Dirección</legend>
              <p class="ml-3 mb-2">
                <strong>Dirección: </strong
                >{{ selectedClient.township?.address }}
              </p>
              <p class="ml-3 mb-2">
                <strong>Provincia: </strong
                >{{ selectedClient.township?.province.name }}
              </p>
              <p class="ml-3 mb-2">
                <strong>Distrito: </strong
                >{{ selectedClient.township?.city.name }}
              </p>
              <p class="ml-3 mb-2">
                <strong>Corregimiento: </strong
                >{{ selectedClient.township?.township.name }}
              </p>
            </fieldset>

            <ng-container *ngIf="selectedType === 'Jurídico'">
              <fieldset>
                <legend>Legal</legend>
                <p class="ml-3 mb-2">
                  <strong>Registro Único de Contribuyente: </strong
                  >{{ selectedClient.data_client?.taxpayer }}
                </p>
                <p class="ml-3 mb-2">
                  <strong>Aviso de Operaciones: </strong
                  >{{ selectedClient.data_client?.operations_notice }}
                </p>
                <p class="ml-3 mb-2">
                  <strong>Fecha de Constitución: </strong
                  >{{ selectedClient.data_client?.constitution_date }}
                </p>
                <p class="ml-3 mb-2">
                  <strong>Representante: </strong
                  >{{ selectedClient.data_client?.representative }}
                </p>
              </fieldset>

              <fieldset>
                <legend>Persona Autorizada</legend>
                <p class="ml-3 mb-2">
                  <strong>Nombre Completo: </strong
                  >{{ selectedClient.data_client?.authorized_person }}
                </p>
                <p class="ml-3 mb-2">
                  <strong>Cédula de Identidad: </strong
                  >{{ selectedClient.data_client?.dni }}
                </p>
                <p class="ml-3 mb-2">
                  <strong>Número de Teléfono: </strong
                  >{{ selectedClient.data_client?.phone }}
                </p>
                <p class="ml-3 mb-2">
                  <strong>Email: </strong
                  >{{ selectedClient.data_client?.email }}
                </p>
              </fieldset>

              <fieldset>
                <legend>Paises de operaciones</legend>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Nombre del País</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let country of selectedClient.operation_countries">
                      <td>{{ country.name }}</td>
                    </tr>
                  </tbody>
                </table>
              </fieldset>
            </ng-container>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>Documentos</a>
        <ng-template ngbNavContent>
          <div class="p-4">
            <ng-container
              *ngIf="selectedClient.documents.length; else elseBlock"
            >
              <ul class="list-group">
                <li
                  *ngFor="let doc of selectedClient.documents"
                  class="list-group-item"
                >
                  <div class="row align-items-center">
                    <div class="col">
                      <h5 class="mb-0">
                        <strong> {{ doc.name }}</strong>
                      </h5>
                      <p>{{ doc.creation_date }}</p>
                    </div>
                    <div class="col-auto text-right">
                      <a
                        [href]="doc.file_url"
                        [download]="doc.name"
                        target="_black"
                        type="application/pdf"
                        ngbTooltip="Descargar"
                        download
                      >
                        <mat-icon aria-hidden="false"> download </mat-icon>
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </ng-container>
            <ng-template #elseBlock>
              <div class="alert alert-info" role="alert">No hay documentos</div>
            </ng-template>

            <hr class="mt-5 mb-0" />

            <form
              class="form-valide"
              action="#"
              method="post"
              [formGroup]="formDoc"
              enctype="multipart/form-data"
              id="formDoc"
              (ngSubmit)="onSubmit()"
              novalidate
            >
              <div class="card-body px-0">
                <div class="form-group text-left">
                  <label class="mb-1"
                    ><strong>Adjuntar documento</strong></label
                  >
                  <div class="input-group">
                    <div class="custom-file">
                      <input
                        type="file"
                        class="custom-file-input"
                        formControlName="file_url"
                        name="file_url"
                        (change)="onDocChange($event)"
                        accept=".pdf"
                        required
                      />
                      <label class="custom-file-label">{{ fileName }}</label>
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <button type="submit" class="btn btn-primary py-2">
                    Subir Archivo
                  </button>
                </div>
              </div>
            </form>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink>Casos</a>
        <ng-template ngbNavContent>
          <div class="p-4">
            <ng-container *ngIf="selectedClient.cases.length; else nodata">
              <div class="table-responsive">
                <table class="table table-sm table-striped">
                  <thead>
                    <tr>
                      <th scope="col" colspan="4">Casos activos</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let case of selectedClient.cases; let i = index"
                    >
                      <td>{{ case.type }}</td>
                      <td>{{ case.name }}</td>
                      <td>{{ case.description }}</td>
                      <td> 
                        <button
                          mat-icon-button
                          color="accent"
                          placement="top"
						  (click)="navigateToCaseEdit(case)"
                          ngbTooltip="Ver Info"
                        >
                          <mat-icon aria-hidden="false" style="color: #212f54">
                            visibility
                          </mat-icon>
                        </button>
					  </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ng-container>
            <ng-template #nodata>
              <div class="alert alert-info" role="alert">
                <strong>No tiene casos activos</strong>
              </div>
            </ng-template>
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
  </div>
</ng-template>

<ng-template #clientModal2>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ isEdit ? "Editar" : "Agregar" }} Cliente
    </h4>
  </div>
  <div class="modal-body">
    <div class="form-row" *ngIf="!isEdit">
      <div class="col-lg-4">
        <div class="form-group">
          <label class="mb-1"><strong>Tipo de cliente</strong></label>
          <select
            class="form-control"
            name="type"
            id="type"
            (change)="onChangeType()"
            [(ngModel)]="selectedType"
          >
            <option selected>Seleccione una opción</option>
            <option [value]="type.value" *ngFor="let type of types">
              {{ type.name }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <ng-container *ngIf="selectedType === 'Natural'">
      <form
        class="form-valide"
        action="#"
        method="post"
        [formGroup]="formNatural"
        (ngSubmit)="onSubmit2()"
        novalidate
      >
        <input
          type="hidden"
          formControlName="type"
          [(ngModel)]="selectedType"
        />
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label class="mb-1"><strong>Nombre Completo</strong></label>
              <input
                type="text"
                class="form-control"
                formControlName="full_name"
              />
              <div
                *ngIf="submitted && n.full_name?.errors"
                class="text-danger fs-13 mt-1"
              >
                <div *ngIf="n.full_name.errors.required">
                  Campo requerido.
                </div>
                <div *ngIf="n.full_name.errors.minlength">
                  Campo debe ser de mínimo 5 caracteres
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label class="mb-1"><strong>Cédula de Identidad</strong></label>
              <input type="text" class="form-control" formControlName="dni" />
              <div
                *ngIf="submitted && n.dni?.errors"
                class="text-danger fs-13 mt-1"
              >
                <div *ngIf="n.dni.errors.required">Campo requerido.</div>
                <div *ngIf="n.dni.errors.minlength">
                  Campo debe ser de mínimo 7 caracteres
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label class="mb-1"><strong>Email</strong></label>
              <input
                type="email"
                class="form-control"
                formControlName="email"
              />
              <div
                *ngIf="submitted && n.email?.errors"
                class="text-danger fs-13 mt-1"
              >
                <div *ngIf="n.email.errors.required">Campo requerido.</div>
                <div *ngIf="n.email.errors.email">Email inválido.</div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label class="mb-1"><strong>Número de Teléfono</strong></label>
              <input
                type="text"
                class="form-control"
                formControlName="phone"
              />
              <div
                *ngIf="submitted && n.phone?.errors"
                class="text-danger fs-13 mt-1"
              >
                <div *ngIf="n.phone.errors.required">Campo requerido.</div>
                <div *ngIf="n.phone.errors.minlength">
                  Campo debe ser de mínimo 7 caracteres
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row align-items-end">
          <div class="col">
            <div class="form-group">
              <label for=""><strong>Provincia</strong></label>
              <select
                class="form-control default-select"
                name=""
                id=""
                (change)="onChange($event.target.value, 'province')"
                [(ngModel)]="provinceId"
                [ngModelOptions]="{ standalone: true }"
              >
                <option disabled selected>Seleccione una opción</option>
                <option [value]="p.id" *ngFor="let p of provinces">
                  {{ p.name }}
                </option>
              </select>
              <div
                *ngIf="submitted && n.township_id?.errors"
                class="text-danger fs-13 mt-1"
              >
                &nbsp;
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for=""><strong>Distrito</strong></label>
              <select
                class="form-control"
                name=""
                id=""
                (change)="onChange($event.target.value, 'city')"
                [(ngModel)]="cityId"
                [ngModelOptions]="{ standalone: true }"
              >
                <option disabled selected>Seleccione una opción</option>
                <option [value]="city.id" *ngFor="let city of cities">
                  {{ city.name }}
                </option>
              </select>
              <div
                *ngIf="submitted && n.township_id?.errors"
                class="text-danger fs-13 mt-1"
              >
                &nbsp;
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for=""
                ><strong>Corregimiento</strong></label
              >
              <select
                class="form-control"
                name="township_id"
                id="township_id"
                (change)="onChange($event.target.value, 'township')"
                [(ngModel)]="townshipId"
                [ngModelOptions]="{ standalone: true }"
              >
                <option disabled selected value="0">
                  Seleccione una opción
                </option>
                <option
                  [value]="township.id"
                  *ngFor="let township of townships"
                >
                  {{ township.name }}
                </option>
              </select>
              <div
                *ngIf="submitted && townshipId == 0"
                class="text-danger fs-13 mt-1"
              >
                Campo requerido.
              </div>
            </div>
          </div>
          <div class="col-auto">
            <div class="form-group">
              <button
                class="btn btn-primary btn-refresh"
                (click)="refreshAddress()"
                placement="top"
                ngbTooltip="refrescar"
              >
                <mat-icon aria-hidden="false"> refresh </mat-icon>
              </button>
              <div
                *ngIf="submitted && n.township_id?.errors"
                class="text-danger fs-13 mt-1"
              >
                &nbsp;
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label class="mb-1"><strong>Dirección</strong></label>
              <input
                type="text"
                class="form-control"
                formControlName="address"
              />
              <div
                *ngIf="submitted && n.address?.errors"
                class="text-danger fs-13 mt-1"
              >
                <div *ngIf="n.address.errors.required">Campo requerido.</div>
                <div *ngIf="n.address.errors.minlength">
                  Campo debe ser de mínimo 5 caracteres
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center">
          <button
            type="button"
            class="btn btn-outline-danger ml-2 mr-2"
            (click)="modal.dismiss()"
          >
            Cancelar
          </button>
          <button type="submit" class="btn btn-primary mr-2">
            {{ isEdit ? "Editar" : "Guardar" }}
          </button>
        </div>
      </form>
    </ng-container>

    <ng-container *ngIf="selectedType === 'Jurídico'">
      <form
        class="form-valide"
        action="#"
        method="post"
        [formGroup]="form"
        (ngSubmit)="onSubmit2()"
        novalidate
      >
        <input
          type="hidden"
          formControlName="type"
          [(ngModel)]="selectedType"
        />

        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label class="mb-1"><strong>Razón Social</strong></label>
              <input
                type="text"
                class="form-control"
                formControlName="business_name"
              />
              <div
                *ngIf="submitted && f.business_name?.errors"
                class="text-danger fs-13 mt-1"
              >
                <div *ngIf="f.business_name.errors.required">
                  Campo requerido.
                </div>
                <div *ngIf="f.business_name.errors.minlength">
                  Campo debe ser de mínimo 5 caracteres
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label class="mb-1"><strong>Razón Comercial</strong></label>
              <input
                type="text"
                class="form-control"
                formControlName="business_reason"
              />
              <div
                *ngIf="submitted && f.business_reason?.errors"
                class="text-danger fs-13 mt-1"
              >
                <div *ngIf="f.business_reason.errors.required">
                  Campo requerido.
                </div>
                <div *ngIf="f.business_reason.errors.minlength">
                  Campo debe ser de mínimo 5 caracteres
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label class="mb-1"
                ><strong>Registro Único de Contribuyente</strong></label
              >
              <input
                type="text"
                class="form-control"
                formControlName="taxpayer"
              />
              <div
                *ngIf="submitted && f.taxpayer?.errors"
                class="text-danger fs-13 mt-1"
              >
                <div *ngIf="f.taxpayer.errors.required">Campo requerido.</div>
                <div *ngIf="f.taxpayer.errors.minlength">
                  Campo debe ser de mínimo 5 caracteres
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label class="mb-1"
                ><strong>Aviso de Operaciones</strong></label
              >
              <input
                type="text"
                class="form-control"
                formControlName="operations_notice"
              />
              <div
                *ngIf="submitted && f.operations_notice?.errors"
                class="text-danger fs-13 mt-1"
              >
                <div *ngIf="f.operations_notice.errors.required">
                  Campo requerido.
                </div>
                <div *ngIf="f.operations_notice.errors.minlength">
                  Campo debe ser de mínimo 5 caracteres
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label class="mb-1"
                ><strong>País de Constitución</strong></label
              >
              <select
                [(ngModel)]="countryConstitutionId"
                class="form-control"
                name=""
                id=""
                formControlName="country_of_constitution_id"
              >
                <option
                  [value]="country.id"
                  *ngFor="let country of countries; let i = index"
                >
                  {{ country.name }}
                </option>
              </select>
              <div
                *ngIf="submitted && f.country_of_constitution_id?.errors"
                class="text-danger fs-13 mt-1"
              >
                <div *ngIf="f.country_of_constitution_id.errors.required">
                  Campo requerido.
                </div>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <label class="mb-1"
                ><strong>Fecha de Constitución</strong></label
              >
              <div class="input-group">
                <input
                  class="form-control"
                  name="inicio"
                  ngbDatepicker
                  #di="ngbDatepicker"
                  formControlName="constitution_date"
                  [(ngModel)]="constitutionDate"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar h-100"
                    (click)="di.toggle()"
                    type="button"
                  ></button>
                </div>
              </div>
              <div
                *ngIf="submitted && f.constitution_date?.errors"
                class="text-danger fs-13 mt-1"
              >
                <div *ngIf="f.constitution_date.errors.required">
                  Campo requerido.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label class="mb-1"><strong>País de Operaciones</strong></label>
              <select
                [(ngModel)]="operationCountries"
                class="form-control"
                name=""
                id=""
                formControlName="operation_countries"
                multiple
              >
                <option
                  [value]="country.id"
                  *ngFor="let country of countries; let i = index"
                >
                  {{ country.name }}
                </option>
              </select>
              <div
                *ngIf="submitted && f.operation_countries?.errors"
                class="text-danger fs-13 mt-1"
              >
                <div *ngIf="f.operation_countries.errors.required">
                  Campo requerido.
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label class="mb-1"><strong>Representante Legal</strong></label>
              <input
                type="text"
                class="form-control"
                formControlName="representative"
              />
              <div
                *ngIf="submitted && f.representative?.errors"
                class="text-danger fs-13 mt-1"
              >
                <div *ngIf="f.representative.errors.required">
                  Campo requerido.
                </div>
                <div *ngIf="f.representative.errors.minlength">
                  Campo debe ser de mínimo 5 caracteres.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label class="mb-1"><strong>Persona Autorizada</strong></label>
              <input
                type="text"
                class="form-control"
                formControlName="authorized_person"
              />
              <div
                *ngIf="submitted && f.authorized_person?.errors"
                class="text-danger fs-13 mt-1"
              >
                <div *ngIf="f.authorized_person.errors.required">
                  Campo requerido.
                </div>
                <div *ngIf="f.authorized_person.errors.minlength">
                  Campo debe ser de mínimo 5 caracteres.
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label class="mb-1"><strong>Cédula de Identidad</strong></label>
              <input type="text" class="form-control" formControlName="dni" />
              <div
                *ngIf="submitted && f.dni?.errors"
                class="text-danger fs-13 mt-1"
              >
                <div *ngIf="f.dni.errors.required">Campo requerido.</div>
                <div *ngIf="f.dni.errors.minlength">
                  Campo debe ser de mínimo 7 caracteres.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label class="mb-1"><strong>Número de Teléfono</strong></label>
              <input
                type="text"
                class="form-control"
                formControlName="phone"
              />
              <div
                *ngIf="submitted && f.phone?.errors"
                class="text-danger fs-13 mt-1"
              >
                <div *ngIf="f.phone.errors.required">Campo requerido.</div>
                <div *ngIf="f.phone.errors.minlength">
                  Campo debe ser de mínimo 7 caracteres.
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label class="mb-1"><strong>Email</strong></label>
              <input
                type="text"
                class="form-control"
                formControlName="email"
              />
              <div
                *ngIf="submitted && f.email?.errors"
                class="text-danger fs-13 mt-1"
              >
                <div *ngIf="f.email.errors.required">Campo requerido.</div>
                <div *ngIf="f.email.errors.email">Email inválido.</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row align-items-end">
          <div class="col">
            <div class="form-group">
              <label for=""><strong>Provincia</strong></label>
              <select
                class="form-control default-select"
                name=""
                id=""
                (change)="onChange($event.target.value, 'province')"
                [(ngModel)]="provinceId"
                [ngModelOptions]="{ standalone: true }"
              >
                <option selected>Seleccione una opción</option>
                <option [value]="p.id" *ngFor="let p of provinces">
                  {{ p.name }}
                </option>
              </select>
              <div
                *ngIf="submitted && f.township_id?.errors"
                class="text-danger fs-13 mt-1"
              >
                &nbsp;
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for=""><strong>Distrito</strong></label>
              <select
                class="form-control"
                name=""
                id=""
                (change)="onChange($event.target.value, 'city')"
                [(ngModel)]="cityId"
                [ngModelOptions]="{ standalone: true }"
              >
                <!-- <option selected>Seleccione una opción</option> -->
                <option [value]="city.id" *ngFor="let city of cities">
                  {{ city.name }}
                </option>
              </select>
              <div
                *ngIf="submitted && f.township_id?.errors"
                class="text-danger fs-13 mt-1"
              >
                &nbsp;
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for=""><strong>Corregimiento</strong></label>
              <select
                class="form-control"
                name="township_id"
                id="township_id"
                (change)="onChange($event.target.value, 'township')"
                [(ngModel)]="townshipId"
                [ngModelOptions]="{ standalone: true }"
              >
                <!-- <option selected>Seleccione una opción</option> -->
                <option
                  [value]="township.id"
                  *ngFor="let township of townships"
                >
                  {{ township.name }}
                </option>
              </select>
              <div
                *ngIf="submitted && townshipId == 0"
                class="text-danger fs-13 mt-1"
              >
                Campo requerido
              </div>
            </div>
          </div>
          <div class="col-auto">
            <div class="form-group">
              <button
                class="btn btn-primary btn-refresh"
                (click)="refreshAddress()"
                placement="top"
                ngbTooltip="refrescar"
              >
                <mat-icon aria-hidden="false"> refresh </mat-icon>
              </button>
              <div
                *ngIf="submitted && f.township_id?.errors"
                class="text-danger fs-13 mt-1"
              >
                &nbsp;
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label class="mb-1"><strong>Dirección</strong></label>
              <input
                type="text"
                class="form-control"
                formControlName="address"
              />
              <div
                *ngIf="submitted && f.address?.errors"
                class="text-danger fs-13 mt-1"
              >
                <div *ngIf="f.address.errors.required">Campo requerido.</div>
              </div>
            </div>
          </div>
        </div>

        <div class="text-center">
          <button
            type="button"
            class="btn btn-outline-danger ml-2"
            (click)="modal.dismiss()"
          >
            Cancelar
          </button>
          <button type="submit" class="btn btn-primary mr-2">
            {{ isEdit ? "Editar" : "Agregar" }}
          </button>
        </div>
      </form>
    </ng-container>
  </div>
</ng-template>
