<section class="content-body d-flex">
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <div class="text-center mb-3">
        <h3>{{titlePage}}</h3>
      </div>
    </div>
    <div class="row">

      <div class="col-12">
        <div class="card border-0">
          <ul ngbNav #nav="ngbNav" class="nav-tabs nav nav-pills nav-fill">
            <li [ngbNavItem]="1">
              <a ngbNavLink class="border-sup">Tramites</a>
              <ng-template ngbNavContent>
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Fecha de Inicio</th>
                          <th scope="col">Número de Caso</th>
                          <th scope="col">Nombre de Cliente</th>
                          <!--<th scope="col">Fase actual</th>-->
                          <th scope="col">Monto a Pagar</th>
                        </tr>
                      </thead>
                      <ng-container *ngIf="listT.length; else nodata">
                        <tbody>
                          <tr *ngFor="let item of listT; let i=index">
                            <td>{{item.init_date}}</td>
                            <td>{{item.assigned_number}}</td>
                            <td>{{item.client.name}}</td>
                            <!--<td></td>-->
                            <td>{{item.price | currency}}</td>
                          </tr>
                          <tr>
                            <td colspan="4"></td>
                            <td>Total: {{getTotalT() | currency}}</td>
                          </tr>
                        </tbody>
                      </ng-container>
                      <ng-template #nodata>
                        <div class="alert" role="alert">
                          <strong>No hay data disponible</strong>
                        </div>
                      </ng-template>
                    </table>
                  </div>
                  <div class="text-center">
                    <button class="btn btn-primary my-4" *ngIf="listT.length" (click)="downloadPdfT()">Descargar</button>
                  </div>
                </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink class="border-sup">Procesos</a>
              <ng-template ngbNavContent>
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Fecha de Inicio</th>
                          <th scope="col">Número de Caso</th>
                          <th scope="col">Nombre de Cliente</th>
                          <!--<th scope="col">Fase actual</th>-->
                          <th scope="col">Monto a Pagar</th>
                        </tr>
                      </thead>
                      <ng-container *ngIf="listP.length; else nodata">
                        <tbody>
                          <tr *ngFor="let item of listP; let i=index">
                            <td>{{item.init_date}}</td>
                            <td>{{item.assigned_number}}</td>
                            <td>{{item.client.name}}</td>
                            <!--<td>{{item.phase}}</td>-->
                            <td>{{item.price | currency}}</td>
                          </tr>
                          <tr>
                            <td colspan="4"></td>

                            <td>Total: ${{getTotalP() | currency}}</td>

                          </tr>
                        </tbody>
                      </ng-container>
                      <ng-template #nodata>
                        <div class="alert" role="alert">
                          <strong>No hay data disponible</strong>
                        </div>
                      </ng-template>
                    </table>
                  </div>
                  <div class="text-center">
                    <button class="btn btn-primary my-4" *ngIf="listP.length" (click)="downloadPdfP()">Descargar</button>
                  </div>
                </div>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav"></div>
        </div>
      </div>
    </div>
  </div>
</section>
