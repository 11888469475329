import { Component,ChangeDetectionStrategy, OnInit } from '@angular/core';
import { LawyerService } from '../../../shared/services/lawyer.service';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { NgbDate, NgbCalendar, NgbDateParserFormatter, NgbModal, ModalDismissReasons, NgbNav, NgbNavItem, NgbNavLink, NgbNavContent } from '@ng-bootstrap/ng-bootstrap';
import { MyDocumentsService } from 'src/app/shared/services/my-documents.service';
import Swal from '../../../shared/helpers/sweetalert';
import { TitlePageService } from '../../../shared/services/title-page.service';
import { Province } from 'src/app/shared/interfaces/province';
import { City } from 'src/app/shared/interfaces/city';
import { Township } from 'src/app/shared/interfaces/township';
import { CommonService } from '../../../shared/services/common.service';
import { Country } from 'src/app/shared/interfaces/country';
import { LawyerClient } from 'src/app/shared/interfaces/user';


@Component({
  selector: 'app-my-clients-lawyer',
  templateUrl: './my-clients-lawyer.component.html',
  styleUrls: ['./my-clients-lawyer.component.css'],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyClientsLawyerComponent implements OnInit {
  List = [];
  active = 1;
  modal: any;
  selectedFilter = '';
  selectedClient: any;
  fileName = 'Seleccione archivo';
  private _docFile = '';
  formDoc: FormGroup;
  docFormData: FormData;
  types = [
    {
      value: 'Natural', name: 'Natural'
    },
    {
      value: 'Jurídico', name: 'Jurídico'
    },
  ];
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  searchFilter = {
    name: '',
    type: '',
    dateStart: null,
    dateEnd: null
  }
  page;
  dataPagination;
  formNatural: FormGroup;
  form: FormGroup;
  provinces: Province[] = [];
  cities: City[] = [];
  cityId = 0;
  client: LawyerClient = {};
  townshipId = 0;
  provinceId = 0;
  isLoading = false;
  townships: Township[] = [];
  countries: Country[] = [];
  countryConstitutionId = 171;
  selectedType = 'Natural';
  isEdit = true;
  constitutionDate: any;
  constructor(
    private lawyerService: LawyerService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private myDocumentsService: MyDocumentsService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    titlePageService: TitlePageService,
    private common: CommonService,
  ) { 
    this.page = 1;
      titlePageService.titlePageObservableData = { name: 'Clientes'};
      this.formDoc = this.fb.group({
        file_url: ['', [Validators.required]],
      });
      this.loadData(this.page);
      this.constitutionDate = this.calendar.getToday();
      this.common.countries().subscribe( response => this.countries = [ ...response.data ] );

  }

  get f(): any { return this.form.controls; }

  ngOnInit(): void {
  }

  navigateToCaseEdit(data) {
	  const url = `${window.location.origin}/lawyer/cases?case=${data.id}`;
	  window.location.href = url;
  }

  get d(): any { return this.formDoc.controls; } 

  onDocChange( e ): void {
    const reader = new FileReader();
    if ( e.target.files && e.target.files.length ) {
      const [ file ] = e.target.files;
      this.fileName = file.name;
      reader.readAsDataURL( file );
      reader.onload = () => {
        this._docFile = reader.result as string;
      };
    }
  }

  onChangeType(): void {
    this.createForm();
  }

  async add(clientModal) {
    if (this.isLoading) return;
    this.isLoading = true;
  
    try {
      // 1. Crea el formulario
      await this.createForm();
      
      // 2. Carga provincias, ciudades y municipios en paralelo (si es posible)
      await this.loadProvinces();
      
      // 3. Reinicia ciudades y municipios
      this.cities = [];
      this.townships = [];
      this.isEdit = false;
  
      // 4. Abre el modal SOLO cuando todo esté listo
      await this.openModal(clientModal);
    } catch (error) {
      console.error("Error al cargar datos:", error);
    } finally {
      this.isLoading = false;
    }
  }
  
  async openModal( clientModal) {
    this.modal = this.modalService.open( clientModal, { backdrop: 'static', size: 'lg' } );
  }

  onChange( id: number, type: string ): void {
    if ( type === 'province' ) { this.loadCities( id ); }
    if ( type === 'city' ) { this.loadTownships( id ); }
    if ( type === 'township' ) { 
      if ( this.selectedType == 'Natural') {
        this.formNatural.controls.township_id.patchValue( id ); 
      } else if( this.selectedType == 'Jurídico') {
        this.form.controls.township_id.patchValue( id ); 
      }
    }
  }
  
  async loadProvinces(): Promise<void> {
    return new Promise( resolve => {
      this.common.provinces( 171 ).subscribe( response => {
        this.provinces = [ ...response.data ];
        this.loadCities( this.provinces[ 0 ].id );
        resolve();
      } );
    } );
  }

  async loadCities( id: number ): Promise<void> {
    return new Promise( resolve => {
      this.common.cities( id ).subscribe( response => {
        this.cities = [ ...response.data ];
        this.townships = [];
        if ( this.isEdit ) {
          this.loadTownships( this.cityId );
        } else {
          this.loadTownships( this.cities[ 0 ].id );
        }
        resolve();
      } );
    } );
  }

  async loadTownships( id: number ): Promise<void> {
    return new Promise( resolve => {
      this.common.townships( id ).subscribe( response => {
        this.townships = [ ...response.data ];
        resolve();
      } );
    } );
  }

  private createForm(): void {
    if ( this.selectedType == 'Natural') {
      this.formNatural = this.fb.group(
        {
          township_id: [ '', [ Validators.required ] ],
          type: [ '', [ Validators.required ] ],
          full_name: [ '', [ Validators.required, Validators.minLength( 5 ) ] ],
          dni: [ '', [ Validators.required, Validators.minLength( 7 ) ] ],
          email: [  '', [ Validators.required, Validators.email ] ],
          address: [ '', [ Validators.required, Validators.minLength( 5 ) ] ],
          phone: [ '', [ Validators.required, Validators.minLength( 7 ) ] ]
        }
      );
    } else if( this.selectedType == 'Jurídico') {
      this.form = this.fb.group(
        {
          township_id: [  '', [ Validators.required ] ],
          type: [ '', [ Validators.required ] ],
          dni: [ '', [ Validators.required, Validators.minLength( 7 ) ] ],
          email: [ '', [ Validators.required, Validators.email ] ],
          address: [ '', [ Validators.required, Validators.minLength( 5 ) ] ],
          phone: [ '', [ Validators.required, Validators.minLength( 7 ) ] ],
          business_name: [ '', [ Validators.required, Validators.minLength( 5 ) ] ],
          business_reason: [ '', [ Validators.required, Validators.minLength( 5 ) ] ],
          taxpayer: [ '', [ Validators.required, Validators.minLength( 5 ) ] ],
          operations_notice: [ '', [ Validators.required, Validators.minLength( 5 ) ] ],
          country_of_constitution_id: [ 171 ],
          constitution_date: [ '', [ Validators.required ] ],
          operation_countries: [ [] , [ Validators.required ] ],
          representative: [ '', [ Validators.required, Validators.minLength( 5 ) ] ],
          authorized_person:[ '', [ Validators.required, Validators.minLength( 5 ) ] ]
        }
      );
    }


  }

  onSubmit() : void {
    if ( this.formDoc.valid ) {

      const formDoc = document.getElementById('formDoc') as HTMLFormElement;
      this.docFormData = new FormData(formDoc);
      this.docFormData.append('lawyer_client_id', this.selectedClient.id.toString() );
      this.docFormData.append('name', this.fileName);

      this.myDocumentsService.uploadDocumentsLaywerClients(this.docFormData).subscribe( response => {
          Swal.fire( { text: 'Registro de documento exitoso', icon: 'success' } );
          this.fileName = 'Seleccione archivo';
          this.formDoc = this.fb.group({
            file_url: ['', [Validators.required]],
          });
          this.modal.dismiss();
      });

    } else {
      Swal.fire( { text: 'Debe cargar un archivo', icon: 'warning' } );

    }
 
  }

    private addClientN(): void {
      this.lawyerService.addClient( this.formNatural.value ).subscribe( () => {
        this.modal.dismiss();
        this.loadData(this.page);
        Swal.fire( { title: 'Cliente creado con exito', icon: 'success', showConfirmButton: true, confirmButtonColor: '#212F54' } );
      } );
    }
  
    private addClientJ(): void {
      this.lawyerService.addClient( this.form.value ).subscribe( () => {
        this.modal.dismiss();
        this.loadData(this.page);
        Swal.fire( { title: 'Cliente creado con exito', icon: 'success', showConfirmButton: true, confirmButtonColor: '#212F54'} );
      } );
    }
  
    private editClientN(): void {
      this.lawyerService.updateClient( this.formNatural.value, this.client.id ).subscribe( () => {
        this.modal.dismiss();
        this.loadData(this.page);
        Swal.fire( { text: 'Cliente actualizado', icon: 'success', confirmButtonColor: '#212F54'} );
      } );
    }
  
    private editClientJ(): void {
      this.lawyerService.updateClient( this.form.value, this.client.id ).subscribe( () => {
        this.modal.dismiss();
       // this.loadData();
        Swal.fire( { text: 'Cliente actualizado', icon: 'success', confirmButtonColor: '#212F54'} );
      } );
    }
  

  onSubmit2(): void {
    //this.submitted = true;
  

    if ( this.selectedType == 'Natural') {

      if ( this.formNatural.valid ) {
        ( this.isEdit ) ? this.editClientN() : this.addClientN();
      }
 
    } else if( this.selectedType == 'Jurídico') {
      this.form.value.constitution_date = this.formatter.format( this.constitutionDate );

      if ( this.form.valid ) {
        ( this.isEdit ) ? this.editClientJ() : this.addClientJ();
      }
    }
  }

  loadData(page) {
    this.lawyerService.clientsLawyer(page).subscribe( response => {
      this.List = [ ...response.data ];
      this.dataPagination = response.meta.page;
      //console.log(response.data);
    });
  }

  loadPage(page) {
    this.page = page;
    this.loadData(this.page);
  }

  openModalInfo(id: number, type: string, clientModal: any) {
    //console.log(id);
    this.selectedType = type;
    this.lawyerService.clientCasesLawyer(id).subscribe( response => {
      this.selectedClient = response.data;
      //console.log(this.selectedClient);
      this.modal = this.modalService.open( clientModal, { backdrop: 'static', size: 'lg' } );
    });
    
  }

  search(){
    this.searchFilter.dateStart = this.formatter.format( this.fromDate );
    this.searchFilter.dateEnd = this.formatter.format( this.toDate );
    let cadena = '';
 
    if(this.searchFilter.name!=='') {
      cadena = cadena+'&search='+this.searchFilter.name;
    }

    if(this.searchFilter.type!=='') {
      cadena = cadena+'&type='+this.searchFilter.type;
    }
    
    if(this.searchFilter.dateStart!=='') {
      cadena = cadena+'&start_date='+this.searchFilter.dateStart;
    }
    
    if( this.searchFilter.dateEnd!=='') {
      cadena = cadena+'&end_date='+this.searchFilter.dateEnd;
    }

    //console.log(cadena);
    if (cadena !== '') {
      this.lawyerService.clientsLawyerFilter(cadena).subscribe( response => {
        this.List = [ ...response.data ];
        //console.log(response.data);
      });
    }
  }

  showClient(id){
    this.lawyerService.clientCasesLawyer(id).subscribe( response => {
      this.selectedClient = response.data;
      //console.log(this.selectedClient);
    });
  }

  clearFilter() {
    this.searchFilter = {
      name: '',
      type: '',
      dateStart: null,
      dateEnd: null
    }
    this.toDate = null;
    this.fromDate = null;
    this.loadData(this.page);
  }


  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }


}
