import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ClientsService } from 'src/app/shared/services/clients.service';
import { TitlePageService } from 'src/app/shared/services/title-page.service';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent implements OnInit {

  modal: any;
  list = [];
  params = '';
  page; dataPagination;
  currentItem: any;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  searchFilter = {
    dateStart: null,
    dateEnd: null
  }
  hoveredDate: NgbDate | null = null;
  titlePage='Finanzas';
  constructor(
    private modalService: NgbModal,
    private clientService: ClientsService,
    public formatter: NgbDateParserFormatter,
    private calendar: NgbCalendar,
    titlePageService: TitlePageService

  ) {
    this.page = 1;
    titlePageService.titlePageObservableData = { name: this.titlePage};
   }

  ngOnInit(): void {
    this.loadData();
  }

  openModal(detailModal: any, item) {
    this.clientService.getPayment(item.id).subscribe(
      response => {
        this.currentItem = response.data;
        console.log(this.currentItem);
        this.modal = this.modalService.open( detailModal, { backdrop: 'static', size: 'md' } );    
      }
    );
  }

  loadData(page = 1) {
    this.clientService.getPayments(page, this.params).subscribe(
      response => {
        this.list = response.data;
        //sort by date asc       
        this.dataPagination = response.meta.page;
    });
  }

  downloadPDF() {
    this.clientService.getPayments(null, this.params, true).subscribe(
     response => {
       var downloadURL = window.URL.createObjectURL( response );
       var link = document.createElement( 'a' );
       link.href = downloadURL;
       link.download = "Facturas.pdf";
       link.click();
   });
 }

  downloadItemPDF(id) {
    this.clientService.getPayment(id, true).subscribe(
      response => {
        var downloadURL = window.URL.createObjectURL( response );
        var link = document.createElement( 'a' );
        link.href = downloadURL;
        link.download = "Facturas.pdf";
        link.click();
      }
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  clearFilter() {
    this.loadData();
    this.searchFilter = {
      dateStart: null,
      dateEnd: null
    }
    this.toDate = null;
    this.fromDate = null;
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  search(){
    this.searchFilter.dateStart = this.formatter.format( this.fromDate );
    this.searchFilter.dateEnd = this.formatter.format( this.toDate );
    this.params = '';

    if (this.searchFilter.dateStart !== '') {
      this.params += '&start_date=' + this.searchFilter.dateStart;
    }

    if (this.searchFilter.dateEnd !== '') {
      this.params += '&end_date=' + this.searchFilter.dateEnd;
    }

    if (this.params !== '') {
      this.loadData(1);
    }
  }
}
