<div class="row">
  <div class="col-12">
    <div class="card border-0">
      <div class="card-header d-block pb-0">
        <form class="">
          <div class="form-group hidden">
            <div class="input-group">
              <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker"
                [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t"
                outsideDays="hidden" [startDate]="fromDate!" tabindex="-1">
              <ng-template #t let-date let-focused="focused">
                <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                  [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                  (mouseleave)="hoveredDate = null">
                  {{ date.day }}
                </span>
              </ng-template>
            </div>
          </div>
          <div class="row justify-content-end align-items-end">

            <div class="col-4 col-md-4">
              <div class="form-group">
                <label class="mb-1"><strong>Desde</strong></label>
                <div class="input-group">
                  <input #dpFromDate class="form-control form-filter-calendar" placeholder="" name="dpFromDate"
                    [value]="formatter.format(fromDate)" (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
                  <div class="input-group-append">
                    <button class="btn form-filter calendar h-100" (click)="datepicker.toggle()" type="button"></button>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-4 col-md-4">
              <div class="form-group">
                <label class="mb-1"><strong>Hasta</strong></label>
                <div class="input-group">
                  <input #dpToDate class="form-control form-filter-calendar" placeholder="" name="dpToDate"
                    [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate.value)">
                  <div class="input-group-append">
                    <button class="btn form-filter calendar h-100" (click)="datepicker.toggle()" type="button"></button>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-4 col-md-4">
              <div class="form-group">
                <label class="mb-1"><strong>Tipo de Servicio</strong></label>
                <select class="form-control form-filter" name="type" id="type" [(ngModel)]="searchFilter.type">
                  <option selected value="">Todos</option>
                  <option [value]="type.id" *ngFor="let type of typesService">{{type.name}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row justify-content-end align-items-end">
            <div class="col-4 col-md-4">
              <div class="form-group">
                <label class="mb-1"><strong>Nombre de Servicio</strong></label>
                <!-- <input class="form-control form-filter" name="service_name" id="service_name" [(ngModel)]="searchFilter.service_name">-->
                <select class="form-control form-filter" name="service_name" id="service_name" [(ngModel)]="searchFilter.service_name">
                  <option selected value="">Todos</option>
                  <option [value]="type.name" *ngFor="let type of types">{{type.name}}</option>
                </select>
              </div>
            </div>
            <div class="col-4 col-md-4">
              <div class="form-group">
                <label class="mb-1"><strong>Cliente</strong></label>
                <input class="form-control form-filter" name="client_name" id="client_name" [(ngModel)]="searchFilter.client_name">
              </div>
            </div>
            <div class="col-4 col-md-4">
              <div class="form-group">
                <div class="input-group">
                  <button class="btn btn-primary btn-filter mr-2" (click)="search()">
                    <i class="fa fa-search"></i>
                  </button>
                  <button class="btn btn-primary btn-filter" (click)="clearFilter()">
                    <i class="fa fa-refresh"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="card-body">
        <ng-container *ngIf="list.length; else nodata">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th style="width: 120px;">Fecha</th>
                  <th scope="col">Tipo de Servicio</th>
                  <th scope="col">Nombre de Servicio</th>
                  <th scope="col">Cliente</th>
                  <th scope="col">Monto</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of list; let i=index">
                  <td>{{item.approval_date}}</td>
                  <td>{{item.service.type_service}}</td>
                  <td>{{item.service.service_name}}</td>
                  <td>{{item.client[0].name}}</td>
                  <td>{{item.amount | currency}}</td>
                  <td>
                    <div>
                      <button mat-icon-button color="accent" aria-label="detail"
                          (click)= "openModal(detailModal, item)" placement="top" ngbTooltip="Ver">
                          <mat-icon aria-hidden="false" aria-label="detail" style="color: #212F54;">
                            visibility
                          </mat-icon>
                        </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="4"></td>
                  <td>Total: {{info_object.Total | currency}}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row justify-content-end mt-3">
            <div class="col-auto custom-div-pagination">
              <ngb-pagination [pageSize]="dataPagination?.perPage" (pageChange)="loadData($event)"
                [collectionSize]="dataPagination?.total" [maxSize]="5" [(page)]="dataPagination.currentPage" aria-label="Paginación">
              </ngb-pagination>
            </div>
          </div>
          <div class="text-center">
            <button class="btn btn-primary my-4" *ngIf="list.length" (click)="downloadPDF()">Descargar</button>
          </div>
        </ng-container>
        <ng-template #nodata>
          <div class="alert alert-warning" role="alert">
            <strong>No hay data disponible</strong>
          </div>
        </ng-template>

      </div>
    </div>
  </div>
</div>

<ng-template #detailModal>
  <div class="modal-header">
      <h4 class="modal-title" >
        Detalle de Cotización</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- {{currentItem | json}} -->
    <div class="row ">
      <div class="col-12 d-flex justify-content-start align-items-center mb-2">
        <label for="" class="font-weight-bold mb-0">Servicio:</label>
        <p class="ml-2 mb-0">{{currentItem.service?.name}}</p>
      </div>
      <div class="col-12 d-flex justify-content-start align-items-center mb-2">
        <label for="" class="font-weight-bold mb-0">Fecha de aprobación:</label>
        <p class="ml-2 mb-0">{{currentItem.available_date}}</p>
      </div>
      <div class="col-12 d-flex justify-content-start align-items-center mb-2">
        <label for="" class="font-weight-bold mb-0">Solicitante:</label>
        <p class="ml-2 mb-0">{{currentItem.user?.full_name}}</p>
      </div>
    </div>
    <div class="row ">
      <div class="col-12 d-flex justify-content-start align-items-center mb-2">
        <label for="" class="font-weight-bold mb-0">Abogado:</label>
        <p class="ml-2 mb-0">{{currentItem.lawyer?.name}}</p>
      </div>
      <div class="col-12 d-flex justify-content-start align-items-center mb-2">
        <label for="" class="font-weight-bold mb-0">Identidad:</label>
        <p class="ml-2 mb-0">{{currentItem.lawyer?.suitability_number}}</p>
      </div>
    </div>
    <div class="text-center mt-4">
      <h4>Cotización de Servicio</h4>
    </div>
    <div class="row justify-content-center">
      <div class="col mt-3">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Concepto</th>
                <th scope="col">Monto</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let phase of currentItem.phases; let i=index">
                <td>{{phase.description}}</td>
                <td>{{phase.amount}}</td>
              </tr>
              <tr>
                <td>Total a Pagar</td>
                <td>{{currentItem.total}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="text-center mt-4">
      <h4>Historico de pagos </h4>
    </div>
    <div class="row justify-content-center">
      <div class="col mt-3">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">#Pago</th>
                <th scope="col">Fase</th>
                <th scope="col">Monto</th>
                <th scope="col">Estado</th>
                <th scope="col">Referencia</th>
                <th scope="col">Comprobante</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let payment of currentItem.order_payments; let i=index">
                <td>{{i + 1}}</td>
                <td>{{payment.phase}}</td>
                <td>{{payment.amount}}</td>
                <td>{{payment.status}}</td>
                <td>{{payment.reference_number}}</td>
                <td class="text-center">
                  <a  *ngIf="payment.reference_file; else message_error"  title="Descargar"  [href]="assetUrl+payment.reference_file"    target="_blank"  >
                    <i class="fa fa-download"  ></i>
                  </a>
                  <ng-template #message_error>
                    <a title="Descargar"   (click)="messageError()" >
                      <i class="fa fa-download"  ></i>
                    </a>
                  </ng-template>
                </td>
                <td>
                    <div *ngIf="payment.status == 'Pendiente'" class="form-row custom-button-card">

                          <button  (click)="approveOrder(payment.id)"  class="btn btn-outline-primary">
                              Aprobar
                            </button>

                          <button (click)="cancelOrder(payment.id)" class="btn btn-danger ">
                              Denegar
                            </button>

                    </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-template>
