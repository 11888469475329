import { filter } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IncomeService } from './../../../shared/services/income.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { StorageService } from 'src/app/shared/services/storage.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LawyerService } from 'src/app/shared/services/lawyer.service';
import { PaymentsService } from 'src/app/shared/services/payments.service';
import { TitlePageService } from 'src/app/shared/services/title-page.service';
import { LAWYER_TYPE } from '../../../shared/data/constants';

@Component({
  selector: 'app-income',
  templateUrl: './income.component.html',
  styleUrls: ['./income.component.css']
})
export class IncomeComponent implements OnInit {
  @ViewChild("formModal", { static: true }) formModal: ElementRef;
  @ViewChild("destroyModal", { static: true }) destroyModal: ElementRef;

  data: any = [];
  selected: any = null;
  user: any;
  lawyerType: string;
  form: FormGroup;
  addModal: any;
  clients: any[] = [];
  cases: any[] = [];
  paymentMethods: any[] = [];
  indexPage: any = {
    total: 0,
    perPage: 10,
    actualPage: 1,
  };
  toDestroyId: number;
  modalData: any;

  constructor(
    private IncomeService: IncomeService,
    private storage: StorageService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    titlePageService: TitlePageService,
    private paymentsService: PaymentsService,
    private lawyerService: LawyerService
  ) { titlePageService.titlePageObservableData = { name: 'Ingresos' }; }

  get f(): any {
    return this.form.controls;
  }

  get canSend(): any {
    return this.form.status === 'VALID';
  }

  get quotes(): any {
    const caseId = this.form.controls.case_id.value;
    const caseFound = this.cases.find((c) => c.id == caseId);
    return caseFound ? caseFound?.quotes.filter((q: any) => q.remaining_amount > 0) ?? [] : [];
  }

  ngOnInit(): void {
    this.user = this.storage.getItem('ln_user');
    this.index();
  }


  index(): void {
    this.lawyerType = this.storage.getItem(LAWYER_TYPE);
    this.IncomeService.index(
        { lawyer_id: this.user?.lawyer?.id }, 
        { 
            page: this.indexPage.actualPage, 
            take: this.indexPage.perPage, 
            includes: JSON.stringify(['case', 'payment_method', 'lawyer_client', 'quote', 'order.service', 'order_client']) 
        }
    ).subscribe((response) => {
        const { data, meta } = response;
        
        // Filtrar datos si lawyerType es "Plataforma"
        if (this.lawyerType === 'Plataforma') {
            this.data = (data ?? []).filter(item => item.order_id === null);
        } else {
          this.data = (data ?? []).filter(item => item.order_id != null);
        }
        
        this.indexPage.total = meta.page?.total;
    });
}

  destroy(id: number, force: boolean = false): any {
    this.toDestroyId = id;
    if (!force) {
      this.modalData = this.modalService.open(this.destroyModal, { size: 'md', centered: true })
      this.modalData.closed.subscribe((response) => {
        this.toDestroyId = null
      })
      return
    }
    return this.IncomeService.deleted(id).subscribe((response) => {
      this.index();
      this.modalService.dismissAll();
      this.toDestroyId = null
    });
  }

  loadPage(page: number) {
    this.indexPage.actualPage = page;
    this.index();
  }

  store(): void {
    if (!this.validateAmount()) return
    let formData = JSON.parse(JSON.stringify(this.form.value));
    formData.lawyer_id = this.user?.lawyer?.id;
    formData.lawyer_client_id = this.caseClient?.id;
    console.log(formData);
    this.IncomeService.store(formData).subscribe((response) => {
      this.addModal.close();
      this.index();
    });
  }

  validateAmount(): boolean {
    const amount = this.form.controls.amount.value;
    const quoteId = this.form.controls.quote_id.value;
    const quote = this.quotes.find((q) => q.id == quoteId);
    if (amount > quote.remaining_amount) {
      this.form.controls.amount.setErrors({ 'invalid': true });
      return false
    }
    return true
  }

  async addModalHandler(entityModal: any): Promise<void> {
    // await this.loadDataClients();
    await this.loadCasesData();
    await this.loadPaymentMethodData();
    this.initForm();
    setTimeout(() => {
      this.form.reset();
    }, 500);
    this.addModal = this.modalService.open(entityModal, { size: 'lg' });
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      case_id: ['', [Validators.required]],
      quote_id: ['', [Validators.required]],
      // lawyer_client_id: ['', [Validators.required]],
      payment_method_id: ['', [Validators.required]],
      amount: ['', [Validators.required]],
      payment_type: ['', [Validators.required]],
      date: ['', [Validators.required]],
    });
  }

  private async loadDataClients(): Promise<void> {
    const response = await this.lawyerService.lawyerClients().toPromise();
    this.clients = [...response.data];
  }

  private async loadPaymentMethodData(): Promise<void> {
    const response = await this.paymentsService.getPaymentMethods().toPromise();
    this.paymentMethods = [...response.data];
  }
  private async loadCasesData(): Promise<void> {
    const response = await this.lawyerService.lawyerCasesSimple(['lawyer_client', 'quotes']).toPromise();
    this.cases = [...response.data];
  }

  reset(): void {
    this.form.reset();
    if (this.addModal) this.addModal.close();
    this.modalService.dismissAll();
  }

  public get caseClient(): any {
    const caseId = this.form.controls.case_id.value;
    const caseFound = this.cases.find((c) => c.id == caseId);
    return caseFound ? caseFound.client : null;
  }

}
