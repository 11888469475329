<div class="my-3">
	<app-cronometer></app-cronometer>
</div>
<div class="card b-d-shadow-h">
  <div class="card-body p-c-body">
    <h4 class="card-title mb-4">Estadísticas del mes</h4>
    <div class="d-grid-1">
      <div (click)="navigateTo('lawyer/cases')" class="clickable b-d-shadow p-w-card">
        <div class="d-grid-2">
            <div class="text-left">
              <p class="mb-1">Casos Activos</p>
              <h2>{{ data?.case_active }}</h2>
            </div>
            <span>
              <i class="las la-hand-holding-usd f-size-36"></i>
            </span>
        </div>
      </div>
      <div (click)="navigateTo('lawyer/my-clients')" class="clickable b-d-shadow p-w-card">
        <div class="d-grid-2">
            <div class="text-left">
              <p class="mb-1">Clientes Activos</p>
              <h2>{{ data?.client_active }}</h2>
            </div>
            <span>
              <i class="las la-users f-size-36"></i>
            </span>
        </div>
      </div>
      <div (click)="navigateTo('lawyer/finances')" class="clickable b-d-shadow p-w-card">
        <div class="d-grid-2">
            <div class="text-left">
              <p class="mb-1">Ingresos Brutos del Mes</p>
              <h2>
                ${{ data?.payment_month | number: "1.2-2":'en-US' }}
              </h2>
            </div>
            <span>
              <i class="la la-dollar f-size-36"></i>
            </span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="d-grid-3">
  <div class="b-d-shadow-h p-c-body b-radius-10">
    <h4 class="card-title mb-4">Avance de Casos</h4>
    <app-detail-pie-chart
      [TypeChart]="Type"
      [DataChild]="parentSubject"
    ></app-detail-pie-chart>
  </div>
  <div class="p-c-body b-d-shadow-h b-radius-10">
    <h4 class="card-title mb-4">Tareas Pendientes</h4>
    <div class="card o-hidden b-d-shadow m-0 h-auto">
      <div class="card-body p-0">
        <div class="table-responsive recentOrderTable">
          <table
            class="table m-0"
          >
            <thead>
              <tr>
                <th scope="col">Tarea</th>
                <th scope="col">Caso</th>
                <th scope="col">Fecha de Inicio</th>
                <th scope="col">Fecha Fin</th>
              </tr>
            </thead>
            <tbody>
              <!-- <tr *ngFor="let d of data.task_pending; let i = index" class="{{ (i%2 == 0 || i==0) ? 'tr-darker' : '' }}">
                <td>{{ d.name_task }}</td>
                <td>{{ d.case_name }}</td>
                <td>{{ d.date_start }}</td>
                <td>{{ d.date_end }}</td>
              </tr> -->
              <tr *ngFor="let d of lawyerCaseTasks; let i = index" class="{{ (i%2 == 0 || i==0) ? 'tr-darker' : '' }}">
                <td>{{ d.name_task }}</td>
                <td>{{ d.case_name }}</td>
                <td>{{ d.date_start | date : "dd-MM-yyyy" }}</td>
                <td>{{ d.date_end | date : "dd-MM-yyyy" }}</td>
              </tr>
            </tbody>
            <tfoot>
              <
            </tfoot>
          </table>
            <div class="justify-content-center mt-3 d-flex">
              <ngb-pagination
                [pageSize]="10"
                (pageChange)="loadPageTaskCasesPrincipal($event)"
                [collectionSize]="dataPaginationTaskPrincipal?.total"
                [maxSize]="5"
                [(page)]="pageTaskCases"
                aria-label="Default pagination"
              ></ngb-pagination>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--
<div class="row ">
  <div class="col-xl-6 col-sm-6 mb-4">
    <div class="d-flex flex-row align-items-center justify-content-start">
      <div>
        <button class="btn btn-primary ml-3">Ver Estado de Cuenta </button>
      </div>
    </div>
  </div>
  <div class="col-xl-6 col-sm-6 ">


    <section>
      <p></p>

    </section>

  </div>
</div>

<div class="row">
  <div class="col-12 col-md-8">
    <div class="col-12 ">
      <div class="card border-card">

        <div class="content-text d-flex flex-column justify-content-around p-3">
          <label for="">Próximos Eventos Agendados:</label>
          <ng-container *ngIf="data.events.length !== 0">
            <div class="list" *ngFor="let event of data.events; index as i; ">
              <span class="number list-number mr-2">{{i+1}}.</span>
              <span>Caso: {{event}}</span>
            </div>
          </ng-container>

          <h4 *ngIf="data.events.length === 0">No hay Próximos Eventos</h4>

        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-4">
    <div class="col-12">
      <div class="card border-card">
        <div class="card-body d-flex align-items-center">
          <div class="mr-auto">
            <p class="mb-2 fs-13">

              Tareas Pendientes
            </p>
            <h2 class="text-black mb-0 font-w600">{{Task_pending}}</h2>
          </div>
          <img alt="" style="width: 40px;" src="/assets/images/task_pending.svg">

        </div>

      </div>
    </div>
  </div>
</div>



<div class="row justify-content-start m-0">
  <div class="col-12 col-md-8">
    <div class="card p-4">
      <div class=" d-flex flex-column justify-content-start ml-1">
        <h2>Porcentaje de Avance de los Casos</h2>

      </div>
      <div>
        <app-detail-pie-chart [TypeChart]="Type" [DataChild]="parentSubject"></app-detail-pie-chart>

      </div>
    </div>
  </div>


</div>

-->

<!--**********************************
    Content body end
***********************************-->
<ng-template #dataInfo>
    <div class="col-xl-8 col-lg-7 col-md-6">
      <div class="card">
        <div class="card-header p-3">
          <h4 class="card-title">Bienvenidos a Legal Nexus</h4>
        </div>
        <div class="card-body p-3">
          <div class="basic-list-group">
            <ul class="list-group">
              <li
                class="list-group-item d-flex justify-content-between align-items-center"
                *ngIf="isDisable('documents')"
              >
                Mis documentos
                <span
                  [routerLink]="['/lawyer/my-documents']"
                  class="cursor-pointer badge badge-primary badge-pill text-white"
                  >Ir</span
                >
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center"
              >
                Casos
                <span
                  [routerLink]="['/lawyer/cases']"
                  class="cursor-pointer badge badge-primary badge-pill text-white"
                  >Ir</span
                >
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center"
              >
                Clientes
                <span
                  [routerLink]="['/lawyer/my-clients']"
                  class="cursor-pointer badge badge-primary badge-pill text-white"
                  >Ir</span
                >
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center"
                *ngIf="isDisable('agendas')"
              >
                Agenda
                <span
                  [routerLink]="['/lawyer/schedule']"
                  class="cursor-pointer badge badge-primary badge-pill text-white"
                  >Ir</span
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
</ng-template>
