<div class="content-body d-flex">
    <div class="container-fluid">
        <div class="card">
            <div class="p-4">
                <div class="d-flex justify-content-between ml-1">
                    <h3>Ingresos</h3>
                    <div>
                        Agregar ingreso
                        <button (click)="addModalHandler(formModal)" mat-icon-button color="accent" placement="top"
                            ngbTooltip="Nuevo ingreso">
                            <mat-icon aria-hidden="false" style="color: #212f54;">
                                add_circle_outline
                            </mat-icon>
                        </button>
                    </div>
                </div>
                <hr />
            </div>
            <div class="card-body">
                <ng-container *ngIf="data?.length; else nodata">
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">N° Factura</th>
                                    <th scope="col">N° Cotización</th>
                                    <th scope="col">Nombre de caso</th>
                                    <th scope="col">Cliente</th>
                                    <th scope="col">Tipo de pago</th>
                                    <th scope="col">Metodo de pago</th>
                                    <th scope="col">Monto</th>
                                    <th scope="col">Monto restante</th>
                                    <!-- <th scope="col">Monto cotización</th> -->
                                    <th scope="col">Fecha</th>
                                    <th style="width: 100px;"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of data; let i=index">
                                    <td class="text-capitalize">{{item?.id}}</td>
                                    <td class="text-capitalize">{{item?.quote?.id}}</td>
                                    <td>{{item?.case?.name || item?.order?.service?.name}}</td>
                                    <td>
                                        {{item?.lawyer_client?.full_name || item?.order_client?.full_name}}
                                    </td>
                                    <td>
                                        {{item.payment_type}}
                                    </td>
                                    <td>
                                        {{item.payment_method.name}}
                                    </td>
                                    <td>
                                        {{item.amount | currency}}
                                    </td>
                                    <td>
                                        {{item.quote?.remaining_amount | currency}}
                                    </td>
                                    <td>
                                        {{item.date | date: 'dd/MM/yyyy'}}
                                    </td>
                                    <td>
                                        <!-- <button mat-icon-button color="accent" placement="top" ngbTooltip="Ver detalle">
                                            <mat-icon aria-hidden="false" style="color: #212f54;">
                                                assignment
                                            </mat-icon>
                                        </button> -->
                                        <button mat-icon-button color="accent" placement="top" ngbTooltip="Eliminar">
                                            <mat-icon aria-hidden="false" style="color: #dc3545;" (click)="destroy(item.id)">
                                                delete
                                            </mat-icon>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row custom-div-pagination justify-content-end">
                        <div class="col-auto">
                            <ngb-pagination [pageSize]="indexPage.perPage" [collectionSize]="indexPage.total"
                                [maxSize]="5" [(page)]="indexPage.actualPage" (pageChange)="loadPage($event)"
                                aria-label="Default pagination"></ngb-pagination>
                        </div>
                    </div>
                </ng-container>
                <ng-template #nodata>
                    <div class="alert alert-warning" role="alert">
                        <strong>
                            No hay data disponible
                        </strong>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>

<!-- Modal Form -->
<ng-template #formModal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            {{ isEdit ? "Editar" : "Agregar" }} Ingreso
        </h4>
    </div>
    <div class="modal-body">
        <!-- (ngSubmit)="onSubmit()" -->

        <form [formGroup]="form" enctype="multipart/form-data" id="form" class="form-valide" novalidate>
            <div class="row">
                <div class="col-6 form-group">
                    <label class="mb-1">
                        <strong>Fecha</strong>
                    </label>
                    <input type="date" class="form-control" formControlName="date" />
                    <div *ngIf="f.date?.errors" class="text-danger fs-13 mt-1">
                        <div *ngIf="f.date.errors.required">Campo requerido.</div>
                    </div>
                </div>
                <div class="col-6 form-group">
                    <label class="mb-1">
                        <strong>Caso</strong>
                    </label>
                    <select class="form-control" formControlName="case_id">
                        <option *ngFor="let item of cases" [value]="item.id">
                            {{item.case_name}}
                        </option>
                    </select>
                    <div *ngIf="f.case_id?.errors" class="text-danger fs-13 mt-1">
                        <div *ngIf="f.case_id.errors.required">Campo requerido.</div>
                    </div>
                </div>
                <div *ngIf="quotes?.length || f?.case_id?.value" class="col-6 form-group">
                    <label class="mb-1">
                        <strong>Cotización</strong>
                    </label>
                    <select class="form-control" formControlName="quote_id">
                        <option *ngFor="let item of quotes" [value]="item.id">
                            #{{item.id}} - Pendiente: ${{item.remaining_amount|number: '1.2-2':'en-US'}}
                        </option>
                    </select>
                    <div *ngIf="f?.quote_id?.errors" class="text-danger fs-13 mt-1">
                        <div *ngIf="f.quote_id.errors.required">Campo requerido.</div>
                    </div>
                </div>
                <div *ngIf="caseClient?.client_name" class="col-6 form-group">
                    <label class="mb-1">
                        <strong>Cliente</strong>
                    </label>
                    <input type="text" class="form-control" disabled [value]="caseClient.client_name" readonly />
                </div>
                <div class="col-6 form-group">
                    <label class="mb-1">
                        <strong>Metodo de pago</strong>
                    </label>
                    <select class="form-control" formControlName="payment_method_id">
                        <option *ngFor="let item of paymentMethods" [value]="item.id">
                            {{item.name}}
                        </option>
                    </select>
                    <div *ngIf="f.payment_method_id?.errors" class="text-danger fs-13 mt-1">
                        <div *ngIf="f.payment_method_id.errors.required">
                            Campo requerido.
                        </div>
                    </div>
                </div>
                <div class="col-6 form-group">
                    <label class="mb-1">
                        <strong>Tipo de pago</strong>
                    </label>
                    <select class="form-control" formControlName="payment_type">
                        <option value="Credito">
                            Credito
                        </option>
                        <option value="Contado">
                            Contado
                        </option>
                    </select>
                    <div *ngIf="f.payment_type?.errors" class="text-danger fs-13 mt-1">
                        <div *ngIf="f.payment_type.errors.required">
                            Campo requerido.
                        </div>
                    </div>
                </div>
                <div class="col-6 form-group">
                    <label class="mb-1">
                        <strong>Monto</strong>
                    </label>
                    <input type="number" class="form-control" formControlName="amount" min="0" step="0.01"  />
                    <div *ngIf="f.amount?.errors" class="text-danger fs-13 mt-1">
                        <div *ngIf="f.amount.errors?.required">Campo requerido.</div>
                        <div *ngIf="f.amount.errors?.invalid">El monto debe ser menor al monto pendiente de la cotización.</div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button (click)="reset()" type="button" class="btn btn-outline-dark">
            Cerrar
        </button>
        <button (click)="store()" type="button" class="btn btn-primary" [disabled]="!canSend">
            Guardar
        </button>
    </div>
</ng-template>

<ng-template #destroyModal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            Eliminar ingreso
        </h4>
    </div>
    <div class="modal-body">
        ¿Desea eliminar el ingreso?
    </div>
    <div class="modal-footer">
        <button (click)="modalData.dismiss()" type="button" class="btn btn-outline-dark">
            Cancelar
        </button>
        <button (click)="destroy(toDestroyId, true)" type="button" class="btn btn-danger">
            Eliminar
        </button>
    </div>
</ng-template>
